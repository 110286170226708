import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './i18n/en.json'
import kr from './i18n/kr.json'
import { parseUserInfo } from './utils/helper'
// the translations
const resources = {
  en: {
    translation: en
  },
  kr: {
    translation: kr
  }
}
let lng = 'en'
try {
  lng = parseUserInfo()?.language
} catch (error) {
  console.error(error)
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lng ? lng : 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
