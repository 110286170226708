export const ROLES = ['No Role', null, 'Affiliate', 'ES', 'Admin']

export const ROLES_DESCRIPTION = ['No Role', '', 'Affiliate', 'ES', 'Administrator']

export enum UserRole {
  NO_ROLE = 0,
  CS = 1,
  AFFILIATE = 2,
  ES = 3,
  ADMIN = 4,
}
