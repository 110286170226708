import * as types from '../../configs/constants/actionType'
import generateActions from './actions'

export function getData(page?: number, size?: number, objectParams?: Object, showExpand?: boolean) {
  return {
    type: generateActions(types.SNSLIST).REQUEST,
    params: { page, size, objectParams },
    showExpand
  }
}

export function getLicenses(objectParams?: Object) {
  return {
    type: generateActions(types.LICENSELIST).REQUEST,
    params: { objectParams }
  }
}

export function getProductList() {
  return {
    type: generateActions(types.PRODUCTLIST).REQUEST
  }
}

export function getLicensePackage(licensePackageId: number) {
  return {
    type: generateActions(types.LICENSE_PACKAGES).REQUEST,
    licensePackageId
  }
}

export function getLicenseKeyDetail(key: string) {
  return {
    type: generateActions(types.LICENSE_KEY_DETAIL).REQUEST,
    key
  }
}

export function setDeactiveLicenseKey(keys: string[]) {
  return {
    type: generateActions(types.LICENSE_PACKAGES_STATUS).REQUEST,
    keys
  }
}

export function changeCCULicensePackage(key: string, data: any) {
  return {
    type: generateActions(types.LICENSE_PACKAGES_DATA).REQUEST,
    key,
    data
  }
}

export function getSapCode() {
  return {
    type: generateActions(types.SAP_CODE).REQUEST
  }
}

export function getValueDropdown() {
  return {
    type: generateActions(types.DROPDOWN).REQUEST
  }
}

export function clearErrorSNS() {
  return {
    type: generateActions(types.CLEAR_ERROR).REQUEST
  }
}

export function generateLK(data: any) {
  return {
    type: generateActions(types.GENERATE_LK).REQUEST,
    data
  }
}

export function getTemplateDetail(sapCode: string) {
  return {
    type: generateActions(types.TEMPLATE_DETAIL).REQUEST,
    sapCode
  }
}

export function renewLicenseKey(key: string) {
  return {
    type: generateActions(types.RENEW_LK).REQUEST,
    key
  }
}

export function clearSelected() {
  return {
    type: types.CLEAR_SELECTED
  }
}

export function clearLicenseByClinicID() {
  return {
    type: types.CLEAR_LICENSE_BY_CLINICID
  }
}

export function deleteLicensePackage(licensePackageId: number) {
  return {
    type: generateActions(types.DELETE_LICENSE_PACKAGE).REQUEST,
    licensePackageId
  }
}

export function getUsbSn() {
  return {
    type: generateActions(types.USB_SN).REQUEST
  }
}

export function updateUsbSn(payload: any) {
  return {
    type: generateActions(types.UPDATE_USB_SN).REQUEST,
    payload
  }
}

export function checkExistedUsbSn(payload: any, onSuccess?: any, onFailure?: any) {
  return {
    type: generateActions(types.CHECK_EXISTED_USB_SN).REQUEST,
    payload,
    onSuccess,
    onFailure
  }
}
