import { call } from 'redux-saga/effects'
import { logout } from '../apiCall'

export function* logoutSaga() {
  try {
    yield call(logout)
  } catch (error) {
    yield console.log(error)
  }
}
