import { AxiosRequestConfig } from 'axios'
import axios from '../configs/axios/axios'
const ENDPOINTS = 'releases'

export function getProductVersions(productIds: number[]) {
  const config: AxiosRequestConfig = {
    params: {
      productIds
    }
  }

  return axios.get(`${ENDPOINTS}/versions`, config).then((res) => res.data)
}
