import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
import * as constants from '../../../configs/constants/releaseList.const'
import { getProductVersions } from '../../../services/release.service'
const ENDPOINTS = 'releases'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    params: {
      page: pageR,
      size,
      ...objectParams
    }
  }
  return axios.get(`${ENDPOINTS}`, config as AxiosRequestConfig).then((res) => res.data)
}

export function* releaseListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.RELEASE_LIST).SUCCESS,
      payload: { releaseList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.RELEASE_LIST).FAILURE })
    Error(err)
  }
}

export function* getReleasesSaga(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.GET_RELEASES).SUCCESS,
      payload: { releases: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.GET_RELEASES).FAILURE })
    Error(err)
  }
}

const _validateInformation = (data: any, isUpdate: boolean = false) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data.release)) {
    formData.append(key, value as string)
  }
  formData.append('isUpdate', `${isUpdate}`)
  return axios.post(`${ENDPOINTS}/validateInformation`, formData).then((res) => res.data)
}

function _createRelease(data: any, folderName: string) {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data.release)) {
    formData.append(key, value as string)
  }
  formData.append('originalname', data.files[0].name)
  formData.append('size', data.files[0].size)
  formData.append('folderName', folderName)
  return axios.post(`${ENDPOINTS}`, formData)
}

export function* createReleaseSaga(action: any) {
  const file = action.data.files[0]
  try {
    const data = yield call(() => _validateInformation(action.data))
    const folderName = data.data.folderName
    const totalChunks = yield call(() => _uploadFile(action.data, folderName))
    yield call(() => _createRelease(action.data, folderName))
    yield put(
      _makingUploadChunkResponseMsg(
        totalChunks,
        totalChunks,
        file.name,
        `${folderName}_${file.name}`
      )
    )
  } catch (err) {
    const errResponse = {
      msg: 'Release is NOT created successfully.',
      status: 'error',
      action: 'add',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.ADD_RELEASE).FAILURE,
      payload: { responseStatus: errResponse }
    })
    Error(err)
  }
}

const getReleaseDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* releaseDetailsSaga(action: any) {
  try {
    const data = yield call(() => getReleaseDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.RELEASE_DETAILS).SUCCESS,
      payload: { releaseDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const _updateRelease = (data: any, isNewReleaseFile: boolean = false, folderName: string = '') => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data.release)) {
    if (key !== 'id') {
      formData.append(key, value as string)
    }
  }
  if (isNewReleaseFile) {
    formData.append('originalname', data.files[0].name)
    formData.append('size', data.files[0].size)
    formData.append('folderName', folderName)
  }
  return axios.patch(`${ENDPOINTS}/${data.release.id}`, formData)
}

export function* updateReleaseSaga(action: any) {
  let isNewReleaseFile = action.data.release && !action.data.release.currentFiles
  try {
    if (!isNewReleaseFile) {
      yield call(() => _updateRelease(action.data))
      yield put(_makingUpdateReleaseResponseMsg(false, false))
    } else {
      const file = action.data.files[0]
      const data = yield call(() => _validateInformation(action.data, true))
      const folderName = data.data.folderName
      const totalChunks = yield call(() => _uploadFile(action.data, folderName, true))
      yield call(() => _updateRelease(action.data, true, folderName))
      yield put(
        _makingUploadChunkResponseMsg(
          totalChunks,
          totalChunks,
          file.name,
          `${folderName}_${file.name}`,
          true
        )
      )
    }
  } catch (err) {
    if (
      err.response?.data?.error?.details?.code === 'DuplicateError' &&
      err.response?.data?.error?.details?.message === 'Require confirm'
    ) {
      yield put(_makingUpdateReleaseResponseMsg(false, true))
    } else {
      yield put(_makingUpdateReleaseResponseMsg(true, false, err.response?.data?.error?.moreInfo))
    }
    Error(err)
  }
}

const deleteRelease = (id: number) => {
  return axios.delete(`${ENDPOINTS}/${id}`)
}

export function* deleteReleaseSaga(action: any) {
  try {
    yield call(() => deleteRelease(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Release is deleted successfully.',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Release is NOT deleted successfully.',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const publishRelease = (id: number, published: boolean) => {
  return axios
    .put(`${ENDPOINTS}/${id}/publish`, null, {
      params: {
        published: published
      }
    })
    .then((res) => res.data)
}

export function* publishReleaseSaga(action: any) {
  try {
    yield call(() =>
      publishRelease(action.data.id, action.data.published).then((res: any) => res.data)
    )
    const successResponse = {
      msg: 'Published state is updated.',
      status: 'success',
      action: 'publish'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: err.response?.data?.error?.moreInfo.includes('Release files have not been uploaded')
        ? 'Release files have not been uploaded!'
        : 'Release is NOT published successfully.',
      status: 'error',
      action: 'publish'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* getVersionSaga(action: any) {
  try {
    const data = yield call(() =>
      getProductVersions(action.params?.productIds).then((res) => res.data)
    )
    yield put({
      type: generateActions(types.VERSION_RELEASE_LIST).SUCCESS,
      payload: { versionList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.VERSION_RELEASE_LIST).FAILURE })
    Error(err)
  }
}

export function* getProductTypeSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/types`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.PRODUCT_TYPE_LIST).SUCCESS,
      payload: { productTypeList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.PRODUCT_TYPE_LIST).FAILURE })
    Error(err)
  }
}

const getVersionByProduct = (productId: number) => {
  const config = {
    params: {
      productId: productId
    }
  }
  return axios
    .get(`${ENDPOINTS}/versions/published`, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* getVersionParentAppSaga(action: any) {
  try {
    const data = yield call(() => getVersionByProduct(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.VERSION_PARENT_APP).SUCCESS,
      payload: { versionParentApp: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

export function* getAllLocaleSaga() {
  try {
    const data = yield call(() => axios.get(`locale-setting/locales`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.LOCALE_ALL).SUCCESS,
      payload: { allLocales: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LOCALE_ALL).FAILURE })
    Error(err)
  }
}

const getVersionsByProduct = (productId: number) => {
  return axios.get(`${ENDPOINTS}/product/${productId}/versions`).then((res) => res.data)
}

export function* getVersionByProductSaga(action: any) {
  try {
    const data = yield call(() => getVersionsByProduct(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.VERSION_BY_PRODUCT).SUCCESS,
      payload: { versionByProduct: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

function* _uploadFile(data: any, folderName: string, isUpdate: boolean = false) {
  const file = data.files[0]
  const { chunks, totalChunks } = _makingChunks(file)

  for (let i = 0; i < totalChunks; i++) {
    try {
      yield _uploadChunk(chunks[i], i, totalChunks, folderName, file.name)
      yield put(
        _makingUploadChunkResponseMsg(
          i,
          totalChunks,
          file.name,
          `${folderName}_${file.name}`,
          isUpdate
        )
      )
    } catch (error) {
      yield put(
        _makingUploadChunkResponseMsg(
          i,
          totalChunks,
          file.name,
          `${folderName}_${file.name}`,
          isUpdate,
          true
        )
      )
      throw error
    }
  }
  return totalChunks
}

function _makingChunks(file: any) {
  // chunk option
  const chunkSize = 5 * 1024 * 1024 // 5mb each
  const chunks = []
  let start = 0
  let end = chunkSize

  while (start < file.size) {
    chunks.push(file.slice(start, end))
    start = end
    end = end + chunkSize
  }
  return { chunks: chunks, totalChunks: chunks.length }
}

function _makingUploadChunkResponseMsg(
  chunkNumber: number,
  totalChunks: number,
  fileName: string,
  chunkName: string,
  isUpdate = false,
  isFailMsg = false
): any {
  const failedTitle = 'Oops! Something went wrong. Please try again.'
  const createSuccessTitle = 'Create new release successfully.'
  const upateSuccessTitle = 'Update release successfully.'
  const uploadingTitle = 'Uploading file...'
  const creatingReleaseTitle = 'Creating new release...'
  const updatingReleaseTitle = 'Updating release information...'
  let title, uploadStatus
  switch (true) {
    case isFailMsg:
      title = failedTitle
      uploadStatus = constants.UPLOAD_RELEASE_FILES_STATUS.FAILED
      break
    case chunkNumber === totalChunks - 1:
      title = isUpdate ? updatingReleaseTitle : creatingReleaseTitle
      uploadStatus = constants.UPLOAD_RELEASE_FILES_STATUS.UPLOADING
      break
    case chunkNumber === totalChunks:
      title = isUpdate ? upateSuccessTitle : createSuccessTitle
      uploadStatus = constants.UPLOAD_RELEASE_FILES_STATUS.SUCCESSFUL
      break
    default:
      title = uploadingTitle
      uploadStatus = constants.UPLOAD_RELEASE_FILES_STATUS.UPLOADING
      break
  }
  return {
    type: types.UPLOAD_RELEASE,
    payload: {
      uploadReleaseFileMsg: { fileName, title, uploadStatus, chunkNumber, totalChunks, chunkName }
    }
  }
}

function _uploadChunk(
  chunk: any,
  chunkNumber: number,
  totalChunks: number,
  folderName: string,
  originalname: string
) {
  const formData = new FormData()
  formData.append('files', chunk)
  formData.append('chunkNumber', `${chunkNumber}`)
  formData.append('totalChunks', `${totalChunks}`)
  formData.append('folderName', `${folderName}`)
  formData.append('originalname', originalname)
  return axios.post(`${ENDPOINTS}/upload`, formData)
}

function _makingUpdateReleaseResponseMsg(
  isErrorResponse: boolean,
  isNeedConfirm: boolean,
  info?: string
) {
  const successMsg = 'Release is updated successfully.'
  const errMsg = 'Release is NOT updated successfully.'
  if (isNeedConfirm) {
    return {
      type: generateActions(types.UPDATE_RELEASE).FAILURE,
      payload: { updateConfirm: true }
    }
  } else {
    const responseMsg = {
      type: isErrorResponse
        ? generateActions(types.UPDATE_RELEASE).FAILURE
        : generateActions(types.UPDATE_RELEASE).SUCCESS,
      payload: {
        responseStatus: {
          msg: isErrorResponse ? errMsg : successMsg,
          action: 'edit',
          status: isErrorResponse ? 'error' : 'success',
          info: info ? info : null
        }
      }
    }
    return responseMsg
  }
}
