import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'release-note'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    page: pageR,
    size,
    ...objectParams
  }
  return axios.post(`${ENDPOINTS}/findAll`, config as AxiosRequestConfig).then((res) => res.data)
}

export function* releaseNoteListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.RELEASE_NOTE_LIST).SUCCESS,
      payload: { releaseNoteList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.RELEASE_NOTE_LIST).FAILURE })
    Error(err)
  }
}

export function* getReleaseNoteListSaga(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.GET_RELEASE_NOTES).SUCCESS,
      payload: { releaseNotes: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.GET_RELEASE_NOTES).FAILURE })
    Error(err)
  }
}

const createReleaseNote = (data: any) => {
  return axios.post(`${ENDPOINTS}`, data)
}

export function* createReleaseNoteSaga(action: any) {
  try {
    yield call(() => createReleaseNote(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Release Note is created successfully.',
      status: 'success',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_RELEASE_NOTE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Release Note is NOT created successfully.',
      status: 'error',
      action: 'add',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.ADD_RELEASE_NOTE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const getReleaseNoteDetails = (releaseId: number, localeCode: string) => {
  const config = {
    params: {
      releaseId: releaseId,
      locale: localeCode
    }
  }
  return axios
    .get(`${ENDPOINTS}/findByLocale`, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* releaseNoteDetailsSaga(action: any) {
  try {
    const data = yield call(() =>
      getReleaseNoteDetails(action.data.releaseId, action.data.locale).then((res: any) => res)
    )
    yield put({
      type: generateActions(types.RELEASE_NOTE_DETAILS).SUCCESS,
      payload: { releaseNoteDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateReleaseNote = (data: any) => {
  return axios.put(`${ENDPOINTS}/${data.id}`, data)
}

export function* updateReleaseNoteSaga(action: any) {
  try {
    yield call(() => updateReleaseNote(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Release Note is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_RELEASE_NOTE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Release Note is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.UPDATE_RELEASE_NOTE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const deleteReleaseNote = (ids: any) => {
  return axios.delete(`${ENDPOINTS}?ids=${ids.join('&ids=')}`)
}

export function* deleteReleaseNoteSaga(action: any) {
  try {
    yield call(() => deleteReleaseNote(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Release Note is deleted successfully.',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE_NOTE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Release Note is NOT deleted successfully.',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RELEASE_NOTE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}
