import React, { Component } from 'react'
import Aux from '../../utils/hoc/_Aux'
import Toolbar from '../../components/Toolbar/Toolbar'
import Breadcrumb from '../../components/Breadcrumb/Breadcumb'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as LoginAction from '../../redux/actions/loginAction'
import Routes from '../../navigation/Routes'

interface LayoutSate {}

interface LayoutProps {
  user: any
  action: any
  history: any
}
class Layout extends Component<LayoutProps, LayoutSate> {
  render() {
    const userInfo = this.props.user.userInfo ? this.props.user.userInfo : {}
    const logout = this.props.action.logout
    return (
      <Aux>
        {this.props.user.userInfo ? (
          <Toolbar title="License Management Portal" userInfo={userInfo} logout={logout}></Toolbar>
        ) : (
          <></>
        )}

        {this.props.user.userInfo && !!this.props.user.userInfo.role ? (
          <Breadcrumb history={this.props.history}/>
        ) : (
          <></>
        )}
        <div
          style={
            window.location.pathname !== '/login-error'
              ? { overflowY: 'auto', height: 'calc(100vh - 128px)' }
              : {}
          }>
          <Routes />
        </div>
      </Aux>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.loginReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    action: bindActionCreators(LoginAction, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
