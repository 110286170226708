import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  getDeletedLicensePackageSaga
} from './deletedLicensePackageSaga'

const snsList = [
  takeLatest(generateActions(types.DELETED_LINCESE_PACKAGE).REQUEST, getDeletedLicensePackageSaga),
]

export default snsList
