import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'areas'

const getDataByPage = (page?: number, size?: number, objectParams?: any) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const body = {
    ...objectParams
  }
  delete body.sort

  const config = {
    params: {
      page: pageR,
      size,
      sort: objectParams.sort
    }
  }
  return axios
    .post(`${ENDPOINTS}/findAll`, body, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* salesAreaListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.AREA_LIST).SUCCESS,
      payload: { salesAreaList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.AREA_LIST).FAILURE })
    Error(err)
  }
}

const createSalesArea = (data: any) => {
  return axios.post(`${ENDPOINTS}`, data)
}

export function* createSalesAreaSaga(action: any) {
  try {
    yield call(() => createSalesArea(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Sales Area is created successfully.',
      status: 'success',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_SALES_AREA).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Sales Area is NOT created successfully.',
      status: 'error',
      action: 'add',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.ADD_SALES_AREA).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const getSalesAreaDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* salesAreaDetailsSaga(action: any) {
  try {
    const data = yield call(() => getSalesAreaDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.SALES_AREA_DETAILS).SUCCESS,
      payload: { salesAreaDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateSalesArea = (data: any) => {
  return axios.patch(`${ENDPOINTS}/${data.id}`, data)
}

export function* updateSalesAreaSaga(action: any) {
  try {
    yield call(() => updateSalesArea(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Sales Area is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_SALES_AREA).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Sales Area is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.UPDATE_SALES_AREA).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const deleteSalesArea = (id: number) => {
  return axios.delete(`${ENDPOINTS}/${id}`)
}

export function* deleteSalesAreaSaga(action: any) {
  try {
    yield call(() => deleteSalesArea(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Sales Area is deleted successfully.',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_SALES_AREA).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Sales Area is NOT deleted successfully.',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_SALES_AREA).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* getCreatorsSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/creators`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.CREATOR_LIST).SUCCESS,
      payload: { creatorsList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.CREATOR_LIST).FAILURE })
    Error(err)
  }
}

export function* getSalesAreasSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/names`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.AREAS_NAME).SUCCESS,
      payload: { salesAreasList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.AREAS_NAME).FAILURE })
    Error(err)
  }
}

export function* getAllAreasSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/all`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.AREAS_ALL).SUCCESS,
      payload: { allAreas: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.AREAS_ALL).FAILURE })
    Error(err)
  }
}

export function* getAllowsSaga() {
  try {
    const data = yield call(() =>
      axios.get(`${ENDPOINTS}/allowedUsers`).then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.ALLOW_LIST).SUCCESS,
      payload: { allowsList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.ALLOW_LIST).FAILURE })
    Error(err)
  }
}

export function* getAllAreaForCurrentUserSaga() {
  try {
    const data = yield call(() =>
      axios.get(`${ENDPOINTS}/currentUser`).then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.AREAS_CURRRENT_USER).SUCCESS,
      payload: { allAreasCurrentUser: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.AREAS_CURRRENT_USER).FAILURE })
    Error(err)
  }
}
