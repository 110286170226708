const moment = require('moment')

export const isEmpty = (args: any): boolean => {
  return args === null || args === undefined || args === '' || args.length === 0
}

export const isNotEmpty = (args: any): boolean => {
  return !isEmpty(args)
}

export const convertDateFormat = (dateInput: string): any => {
  if (dateInput) {
    const date = moment(dateInput)
    return date.format('YYYY/MM/DD')
  } else {
    return null
  }
}

export const getDefaultValue = (conditions: any[], value: any, defaultValue: any) => {
  if (conditions.every((condition) => !!condition)) {
    return value
  }
  return defaultValue
}

export function sortAlphabet(a: string, b: string) {
  if (a > b) {
    return 1
  }
  return -1
}

export function sortByDisplayValue(arr: any[]) {
  return arr.sort((a: any, b: any) => a.displayValue?.localeCompare(b.displayValue))
}

export const getDefaultValueInvert = (conditions: any[], value: any, defaultValue: any) => {
  if (conditions.some((condition) => !!condition)) {
    return value
  }
  return defaultValue
}

export const checkLicenseExpired = (expiresAt: any) => {
  if (isNotEmpty(expiresAt) && new Date(expiresAt) < new Date()) {
    return true
  }
  return false
}

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const parseUserInfo = () => {
  const userInfo = localStorage?.getItem('userInfo') as any
  if (!userInfo) {
    return {}
  }
  try {
    return JSON.parse(userInfo as string)
  } catch (error) {
    console.error('🚀 ~ parseUserInfo ~ parseUserInfo:', error)
    return {}
  }
}

export const convertHtmlToText = (value: string) => {
  const regex = /(<([^>]+)>)/gi
  return value?.replace(regex, '').replace(/&nbsp;/g, ' ').trim()
}

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))