import Environment from '../configs/environment/environment'

// const msalInstance = new msal.PublicClientApplication(msalConfig);
export default class AuthService {
  // msalInstance: any;
  enviroment: Environment
  constructor() {
    // this.msalInstance = msalInstance;
    this.enviroment = new Environment()
  }

  // login = () => this.msalInstance.loginPopup({
  //     scopes: applicationConfig.graphScopes,
  //     prompt: 'select_account',
  //   }).then((loginResponse: any) => {
  //     console.log('loginResponse: ' + JSON.stringify(loginResponse))
  //     return loginResponse
  //   }).catch((error: any) => {
  //     console.log('signIn ERROR: ' + JSON.stringify(error.stack))
  // });

  logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('userInfo')
    window.location.href = `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${this.enviroment.redirectUri}`
    // this.msalInstance.logout();
  }

  // getToken = () => {
  //   const currentAccount = JSON.parse(localStorage.getItem('user') as any)
  //     .account;
  //   return this.msalInstance
  //     .acquireTokenSilent({
  //       scopes: applicationConfig.graphScopes,
  //       account: currentAccount,
  //       forceRefresh: false,
  //       prompt: 'none'
  //     })
  //     .then(
  //       (accessToken: any) => {
  //         return accessToken;
  //       },
  //       (e: any) => {
  //         console.log(e);
  //         return this.msalInstance
  //           .acquireTokenPopup({ scopes: applicationConfig.graphScopes })
  //           .then(
  //             (accessToken: any) => {
  //               return accessToken;
  //             },
  //             (err: any) => {
  //               console.error(err);
  //             }
  //           );
  //       }
  //     );
  // };
}
