import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
import { getProductVersions } from '../../../services/release.service'
const ENDPOINTS = 'auto-download'

const getDataByPage = (page?: number, size?: number, objectParams?: any) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const body = {
    ...objectParams
  }
  delete body.sort

  const config = {
    params: {
      page: pageR,
      size,
      sort: objectParams.sort
    }
  }
  return axios
    .post(`${ENDPOINTS}/findAll`, body, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* rCListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.RC_LIST).SUCCESS,
      payload: { autoDownloadList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.RC_LIST).FAILURE })
    Error(err)
  }
}

const createRC = (data: any) => {
  return axios.post(`${ENDPOINTS}`, data)
}

export function* createRCSaga(action: any) {
  try {
    yield call(() => createRC(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Auto-Download Config successfully.',
      status: 'success',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_RC).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Auto-Download Config NOT added successfully.',
      status: 'error',
      action: 'add',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.ADD_RC).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const getRCDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* rCDetailsSaga(action: any) {
  try {
    const data = yield call(() => getRCDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.RC_DETAILS).SUCCESS,
      payload: { rcDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateRC = (data: any) => {
  return axios.patch(`${ENDPOINTS}/${data.id}`, data).then((res) => res.data)
}

export function* updateRCSaga(action: any) {
  const { denied } = action.data
  try {
    yield call(updateRC, action.data)
    const successResponse = {
      msg: denied
        ? 'Denied item is created successfully'
        : 'Auto-Download Config is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_RC).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg:
        err.response?.data?.error?.moreInfo === 'required_locales_not_registered'
          ? 'required_locales_not_registered'
          : denied
          ? 'Denied item is NOT created successfully'
          : 'Auto-Download Config is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.details
    }
    yield put({
      type: generateActions(types.UPDATE_RC).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const deleteRC = (id: number) => {
  return axios.delete(`${ENDPOINTS}/${id}`)
}

export function* deleteRCSaga(action: any) {
  try {
    yield call(() => deleteRC(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Denied Item is deleted successfully.',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RC).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Denied Item is NOT deleted successfully.',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_RC).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* getVersionSaga(action: any) {
  try {
    const data = yield call(() =>
      getProductVersions(action.params?.productIds).then((res) => res.data)
    )
    yield put({
      type: generateActions(types.VERSION_AUTO_LIST).SUCCESS,
      payload: { versionList: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.VERSION_AUTO_LIST).FAILURE })
    Error(err)
  }
}

const getVersionByProduct = (productId: number) => {
  const config = {
    params: {
      productId: productId
    }
  }
  return axios
    .get(`releases/versions/published`, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* getVersionsPublishedByProductSaga(action: any) {
  try {
    const data = yield call(() => getVersionByProduct(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.VERSION_PARENT_APP).SUCCESS,
      payload: { versionPublished: data.data }
    })
  } catch (error) {
    Error(error)
  }
}
