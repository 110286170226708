import { put, call, select } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
import CountriesData from '../../../configs/constants/country.json'
const ENDPOINTS = 'locale-setting'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    page: pageR,
    size: Number(size),
    ...objectParams
  }
  return axios.post(`${ENDPOINTS}/findAll`, config as AxiosRequestConfig).then((res) => res.data)
}

export function* localeListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.LOCALE_LIST).SUCCESS,
      payload: { localeList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LOCALE_LIST).FAILURE })
    Error(err)
  }
}

export function* getAllLocaleSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/locales`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.LOCALE_ALL).SUCCESS,
      payload: { allLocales: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LOCALE_ALL).FAILURE })
    Error(err)
  }
}

const getLocaleDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* localeDetailsSaga(action: any) {
  try {
    const data = yield call(() => getLocaleDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.LOCALE_DETAILS).SUCCESS,
      payload: { localeDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateLocale = (data: any) => {
  return axios.put(`${ENDPOINTS}/${data.id}`, data)
}

const handleMsg = (errorInfo: string, actionData: any, allLocales: any) => {
  const { countryCode, defaultLocale } = actionData
  const country = CountriesData.find((item) => item.code === countryCode)
  const locale = allLocales?.find((item: any) => item.code === defaultLocale)

  switch (errorInfo) {
    case 'release_note_locale_not_registered':
      return `Please register release notes for [${locale?.name}] before changing Default Locale to [${locale?.name}]`
    case 'auto_download_not_enabled':
      return `Auto-download is not enabled for [${country?.name}]. Please enable auto-download for any release from Auto-Download before changing Default Locale to [${locale?.name}]`
    default:
      return 'Locale is NOT updated successfully.'
  }
}

export function* updateLocaleSaga(action: any) {
  try {
    yield call(() => updateLocale(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Locale is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_LOCALE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const allLocales = yield select((state: any) => state.localeReducer.allLocales)

    const errResponse = {
      msg: handleMsg(err.response?.data?.error?.moreInfo, action.data, allLocales),
      status: err.response?.data?.error?.details ? 'message' : 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo,
      details: err.response?.data?.error?.details
    }
    yield put({
      type: generateActions(types.UPDATE_LOCALE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}
