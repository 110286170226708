import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const productReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.PRODUCTLIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.PRODUCTSINFO).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.PRODUCTSINFODATA).REQUEST:
      return Object.assign({}, state, { isLoading: true, rows: [], count: 0 })
    case generateActions(types.PRODUCTSINFODATA).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.PRODUCTSINFODATA).FAILURE:
      return Object.assign({}, { isLoading: false, count: 0, row: [] })
    case generateActions(types.CRYPTLEXPRODUCTS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CRYPTLEXPOLICIES).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ADD_NEW_PRODUCT).REQUEST:
      return Object.assign({}, state, { isLoadingAPI: true })
    case generateActions(types.ADD_NEW_PRODUCT).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.ADD_NEW_PRODUCT).FAILURE:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.UPDATE_PRODUCT).REQUEST:
      return Object.assign({}, state, { isLoadingAPI: true })
    case generateActions(types.UPDATE_PRODUCT).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.UPDATE_PRODUCT).FAILURE:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.DELETE_PRODUCT).REQUEST:
      return Object.assign({}, state, { isLoadingAPI: true })
    case generateActions(types.DELETE_PRODUCT).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.DELETE_PRODUCT).FAILURE:
      return Object.assign({}, state, action.payload, { isLoadingAPI: false })
    case generateActions(types.CLEAR_ERROR_PRODUCT).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    default:
      return state
  }
}

export default productReducer
