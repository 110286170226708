import * as types from '../../configs/constants/actionType'
import { parseUserInfo } from '../../utils/helper'
import generateActions from '../actions/actions'

const userMnReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.DATA_USERS).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })

    case generateActions(types.DATA_USERS).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.DATA_USERS).FAILURE:
      return Object.assign({}, state, { isLoading: false, count: 0, rows: [] })

    case generateActions(types.ROLES).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.UPDATE_USER).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.UPDATE_USER).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.CHANGE_LANGUAGE).SUCCESS:
      return Object.assign({}, state, { changeLanguage: true })

    case generateActions(types.UM_CLEAR_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })

    case 'RESET_DATA_USER':
      const userInfo = parseUserInfo()
      const targetUser = state.rows?.find((el: any) => el.id === userInfo.id)
      if (targetUser) {
        targetUser.language = userInfo.language
        return Object.assign({}, state)
      } else {
        return state
      }
    default:
      return state
  }
}

export default userMnReducer
