import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const packageTemplateReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.LP_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.LP_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.LP_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })

    case generateActions(types.LP_CREATE).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    case generateActions(types.LP_CREATE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.LP_CREATE).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LP_UPDATE).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    case generateActions(types.LP_UPDATE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.LP_UPDATE).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LP_DELETE).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    case generateActions(types.LP_DELETE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.LP_DELETE).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.COMPANY).REQUEST:
      return Object.assign({}, state, { company: null })
    case generateActions(types.COMPANY).SUCCESS:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default packageTemplateReducer
