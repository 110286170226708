import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  releaseNoteListSagaByPage,
  createReleaseNoteSaga,
  releaseNoteDetailsSaga,
  updateReleaseNoteSaga,
  deleteReleaseNoteSaga,
  getReleaseNoteListSaga
} from './releaseNoteSaga'

const releaseNoteSaga = [
  takeLatest(generateActions(types.RELEASE_NOTE_LIST).REQUEST, releaseNoteListSagaByPage),
  takeLatest(generateActions(types.ADD_RELEASE_NOTE).REQUEST, createReleaseNoteSaga),
  takeLatest(generateActions(types.RELEASE_NOTE_DETAILS).REQUEST, releaseNoteDetailsSaga),
  takeLatest(generateActions(types.UPDATE_RELEASE_NOTE).REQUEST, updateReleaseNoteSaga),
  takeLatest(generateActions(types.DELETE_RELEASE_NOTE).REQUEST, deleteReleaseNoteSaga),
  takeLatest(generateActions(types.GET_RELEASE_NOTES).REQUEST, getReleaseNoteListSaga)
]

export default releaseNoteSaga
