import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const releaseReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.RELEASE_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.RELEASE_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.RELEASE_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })
    case generateActions(types.ADD_RELEASE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.ADD_RELEASE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ADD_RELEASE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.RELEASE_DETAILS).REQUEST:
      return Object.assign({}, state, { releaseDetails: null })
    case generateActions(types.RELEASE_DETAILS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RELEASE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.UPDATE_RELEASE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RELEASE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RELEASE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.DELETE_RELEASE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RELEASE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.PUBLISH_RELEASE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.PUBLISH_RELEASE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.PUBLISH_RELEASE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.VERSION_RELEASE_LIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.PRODUCT_TYPE_LIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.VERSION_PARENT_APP).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.LOCALE_ALL).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CLEAR_RELEASE_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    case generateActions(types.GET_RELEASES).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.CLEAR_RELEASE_BY_PRODUCT).REQUEST:
      return Object.assign({}, state, { releases: { rows: [] } })
    case generateActions(types.VERSION_BY_PRODUCT).SUCCESS:
      return Object.assign({}, state, action.payload)
    case types.UPLOAD_RELEASE:
      return Object.assign({}, state, action.payload)
    case types.CLOSE_UPDATE_CONFIRM_MODAL:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default releaseReducer
