import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  releaseListSagaByPage,
  createReleaseSaga,
  releaseDetailsSaga,
  updateReleaseSaga,
  deleteReleaseSaga,
  publishReleaseSaga,
  getVersionSaga,
  getProductTypeSaga,
  getVersionParentAppSaga,
  getAllLocaleSaga,
  getVersionByProductSaga,
  getReleasesSaga
} from './releaseManagementSaga'

const releaseListSaga = [
  takeLatest(generateActions(types.RELEASE_LIST).REQUEST, releaseListSagaByPage),
  takeLatest(generateActions(types.ADD_RELEASE).REQUEST, createReleaseSaga),
  takeLatest(generateActions(types.RELEASE_DETAILS).REQUEST, releaseDetailsSaga),
  takeLatest(generateActions(types.UPDATE_RELEASE).REQUEST, updateReleaseSaga),
  takeLatest(generateActions(types.DELETE_RELEASE).REQUEST, deleteReleaseSaga),
  takeLatest(generateActions(types.PUBLISH_RELEASE).REQUEST, publishReleaseSaga),
  takeLatest(generateActions(types.VERSION_RELEASE_LIST).REQUEST, getVersionSaga),
  takeLatest(generateActions(types.PRODUCT_TYPE_LIST).REQUEST, getProductTypeSaga),
  takeLatest(generateActions(types.VERSION_PARENT_APP).REQUEST, getVersionParentAppSaga),
  takeLatest(generateActions(types.LOCALE_ALL).REQUEST, getAllLocaleSaga),
  takeLatest(generateActions(types.VERSION_BY_PRODUCT).REQUEST, getVersionByProductSaga),
  takeLatest(generateActions(types.GET_RELEASES).REQUEST, getReleasesSaga)
]

export default releaseListSaga
