import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  rCListSagaByPage,
  createRCSaga,
  rCDetailsSaga,
  updateRCSaga,
  deleteRCSaga,
  getVersionSaga,
  getVersionsPublishedByProductSaga,
} from './autoDownloadManagementSaga'

const autoDownloadManagementSaga = [
  takeLatest(generateActions(types.RC_LIST).REQUEST, rCListSagaByPage),
  takeLatest(generateActions(types.ADD_RC).REQUEST, createRCSaga),
  takeLatest(generateActions(types.RC_DETAILS).REQUEST, rCDetailsSaga),
  takeLatest(generateActions(types.UPDATE_RC).REQUEST, updateRCSaga),
  takeLatest(generateActions(types.DELETE_RC).REQUEST, deleteRCSaga),
  takeLatest(generateActions(types.VERSION_AUTO_LIST).REQUEST, getVersionSaga),
  takeLatest(generateActions(types.VERSION_PUBLISHED).REQUEST, getVersionsPublishedByProductSaga),
]

export default autoDownloadManagementSaga
