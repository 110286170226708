import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
import { isNotEmpty } from '../../../utils/helper'

const ENDPOINTS = '/users'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    params: {
      page: pageR,
      size,
      ...objectParams
    }
  }

  return axios.get(ENDPOINTS, config as AxiosRequestConfig).then((res) => res.data)
}

const updateUsers = (
  userId: any,
  role: any,
  areas: any,
  status: any,
  canEditCcu: number,
  autoUpdateSettingPermission: number
) => {
  const userUpdate = {
    userId,
    role: +role,
    status,
    canEditCcu,
    autoUpdateSettingPermission
  } as any

  if (isNotEmpty(areas) && areas !== 'None') {
    const arr = areas.split(',')
    const numbers = []
    for (let i = 0; i < arr.length; i++) {
      numbers[i] = Number(arr[i])
    }
    userUpdate.areas = numbers
  } else {
    userUpdate.areas = []
  }

  return axios.put(ENDPOINTS, userUpdate).then((res) => res.data)
}

const changeLanguage = (language: string, userId: string) => {
  return axios.put(ENDPOINTS, { language, userId }).then((res) => res.data)
}

export function* userMnSaga(action: any) {
  try {
    if (action.params.objectParams) {
      Object.keys(action.params.objectParams).forEach((ele: any) => {
        if (typeof action.params.objectParams[ele] == 'string') {
          action.params.objectParams[ele] = action.params.objectParams[ele].trim()
        }
      })
    }
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.DATA_USERS).SUCCESS,
      payload: { ...data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.DATA_USERS).FAILURE })
    Error(err)
  }
}

export function* getRoleSaga() {
  try {
    const data = yield axios.get(`${ENDPOINTS}/roles/all`).then((res: any) => res.data)
    yield put({
      type: generateActions(types.ROLES).SUCCESS,
      payload: { roles: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.ROLES).FAILURE })
    Error(err)
  }
}

export function* updateUserSaga(action: any) {
  try {
    const userEdit = yield call(() =>
      updateUsers(
        action.body.userId,
        action.body.role,
        action.body.areas,
        action.body.status,
        action.body.canEditCcu,
        action.body.autoUpdateSettingPermission
      ).then((res: any) => res.data)
    )

    const successResponse = {
      msg: 'The user information has been updated successfully',
      status: 'success'
    }

    yield put({
      type: generateActions(types.UPDATE_USER).SUCCESS,
      payload: { responseStatus: { ...successResponse }, userEdit }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to update the user information',
      status: 'error'
    }
    yield put({
      type: generateActions(types.UPDATE_USER).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* changeLanguageSaga(action: any) {
  try {
    const data = yield call(() =>
      changeLanguage(action.body.lang, action.body.userId).then((res: any) => res.data)
    )
    yield put({ type: generateActions(types.CHANGE_LANGUAGE).SUCCESS })
    const userInfo = yield localStorage.getItem('userInfo')
    yield localStorage.setItem(
      'userInfo',
      JSON.stringify(Object.assign({}, JSON.parse(userInfo), { language: data.language }))
    )
    if (window.location.pathname.includes('/user-management')) {
      yield put({ type: 'RESET_DATA_USER' })
    }
  } catch (err) {
    yield put({ type: generateActions(types.CHANGE_LANGUAGE).FAILURE })
    Error(err)
  }
}
