import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import './Spinner.scss'
import Group_2472 from '../../../assets/images/Group_2472.png'
import Group_2472_invert from '../../../assets/images/Group_2472_invert.png'
import { useTranslation } from 'react-i18next'

interface SpinnerProps {
  isAbsolute?: boolean
  invert?: boolean
}
const SpinnerCustom: React.FC<SpinnerProps> = ({ isAbsolute = false, invert }) => {
  const { t } = useTranslation()
  const styleObj: any = isAbsolute
    ? {
        position: 'absolute',
        top: '50vh',
        left: '0vw',
        zIndex: '1000' as any
      }
    : null
  return (
    <div className="spinner-container" style={styleObj}>
      <Spinner animation="border" role="status">
        <img src={invert ? Group_2472_invert : Group_2472} alt="loading" />
      </Spinner>
      <span className="loading" style={invert ? { color: '#fff' } : {}}>
        {t('Loading')}...
      </span>
    </div>
  )
}

export default SpinnerCustom
