import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import router from './router'
import PrivateRoute from './PrivateRoute'
import Spinner from '../components/Common/Spinner/SpinnerCustom'
import Modal from 'react-bootstrap/Modal'

class Temple extends React.Component {
  render() {
    const userInfo = localStorage.getItem('userInfo')
    return userInfo ? (
      JSON.parse(userInfo).role > 0 ? (
        <Redirect to="/license-key-list" />
      ) : (
        <Redirect to="/login-error" />
      )
    ) : (
      <Modal
        show={true}
        animation={false}
        keyboard={false}
        backdrop="static"
        className="backdrop-login">
        <Spinner invert={true} />
      </Modal>
    )
  }
}

const Routes = (props: any) => {
  return (
    <Switch>
      {router.map((route, index) => {
        return route.needAuthenticate ? (
          <PrivateRoute
            key={index}
            path={route.path}
            roleAllow={route.roleAllow}
            component={route.component}
            exact={route.exact}
          />
        ) : (
          <Route path={route.path} key={index} component={route.component} exact={route.exact} />
        )
      })}
      <Route path="/" component={Temple} />
    </Switch>
  )
}

export default Routes
