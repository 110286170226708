import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  localeListSagaByPage,
  updateLocaleSaga,
  localeDetailsSaga,
  getAllLocaleSaga
} from './localeSettingSaga'

const releaseNoteSaga = [
  takeLatest(generateActions(types.LOCALE_LIST).REQUEST, localeListSagaByPage),
  takeLatest(generateActions(types.LOCALE_ALL).REQUEST, getAllLocaleSaga),
  takeLatest(generateActions(types.UPDATE_LOCALE).REQUEST, updateLocaleSaga),
  takeLatest(generateActions(types.LOCALE_DETAILS).REQUEST, localeDetailsSaga)
]

export default releaseNoteSaga
