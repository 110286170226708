import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const salesAreaReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.AREA_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.AREA_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.AREA_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })
    case generateActions(types.ADD_SALES_AREA).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.ADD_SALES_AREA).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ADD_SALES_AREA).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.SALES_AREA_DETAILS).REQUEST:
      return Object.assign({}, state, { salesAreaDetails: null })
    case generateActions(types.SALES_AREA_DETAILS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_SALES_AREA).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.UPDATE_SALES_AREA).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_SALES_AREA).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_SALES_AREA).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.DELETE_SALES_AREA).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_SALES_AREA).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.AREAS_NAME).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.AREAS_ALL).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CREATOR_LIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ALLOW_LIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CLEAR_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    case generateActions(types.AREAS_CURRRENT_USER).REQUEST:
      return Object.assign({}, state, action.payload, { isLoading: true })
    case generateActions(types.AREAS_CURRRENT_USER).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.AREAS_CURRRENT_USER).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false })
    default:
      return state
  }
}

export default salesAreaReducer
