import React, { Component } from 'react'

export default function asyncComponent(getComponent: Function) {
  class AsyncComponent extends Component {
    static Component: any = null
    state = {
      // in here, it return itself
      Component: AsyncComponent.Component
    }

    componentDidMount() {
      // don't re-load if Component existed
      if (!this.state.Component) {
        getComponent().then((RenderComponent: any) => {
          AsyncComponent.Component = RenderComponent
          this.setState({ Component: RenderComponent })
        })
      }
    }

    render() {
      const { Component: RenderComponent } = this.state
      if (RenderComponent) {
        return <RenderComponent {...this.props} />
      }
      return null
    }
  }
  return AsyncComponent
}
