import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'

interface Action {
  type: string
  params: {
    page: number
    size: number
    objectParams: any
  }
  showExpand: boolean
}

const ENDPOINTS = 'licenses'
const getDataByPage = (page?: number, size?: number, objectBody?: object, showExpand?: boolean) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const body = {
    page: pageR,
    size,
    ...objectBody
  } as any
  if (!showExpand) {
    body.status = ['0', '1']
  }
  if (body.includeExpired === undefined) {
    body.includeExpired = true
  }
  return axios.post(`${ENDPOINTS}/search`, JSON.stringify(body)).then((res) => res.data)
}

export function* snsListSaga(action: Action) {
  try {
    if (action.params.objectParams) {
      Object.keys(action.params.objectParams).forEach((ele: any) => {
        if (typeof action.params.objectParams[ele] == 'string') {
          action.params.objectParams[ele] = action.params.objectParams[ele].trim()
        }
      })
    }
    const data = yield call(() =>
      getDataByPage(
        action.params.page,
        action.params.size,
        action.params.objectParams,
        action.showExpand
      ).then((res: any) => res)
    )
    yield put({
      type: generateActions(types.SNSLIST).SUCCESS,
      payload: { ...data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.SNSLIST).FAILURE })
    Error(err)
  }
}

const getData = (clinicId: any) => {
  return axios.get(`${ENDPOINTS}/searchByClinicID/${clinicId}`).then((res) => res)
}

export function* licenseListSaga(action: Action) {
  try {
    const data = yield call(() => getData(action.params.objectParams).then((res: any) => res))
    yield put({
      type: generateActions(types.LICENSELIST).SUCCESS,
      payload: { listLicenseByClinicID: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LICENSELIST).FAILURE })
    Error(err)
  }
}
