import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'

export function* getDasProductSaga(action: any) {
  try {
    let params: any = {}
    if (!action.params) {
      params.licenseType = 'Perpetual'
    } else {
      params = action.params
      delete params.period
    }
    const products = yield call(() =>
      axios.get('licenses/dashboard/products', { params }).then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.DAS_PRODUCT).SUCCESS,
      payload: { products }
    })
  } catch (err) {
    yield put({ type: generateActions(types.DAS_PRODUCT).FAILURE })
    Error(err)
  }
}

export function* getDasMonthSaga(action: any) {
  try {
    let params: any = {}
    if (!action.params) {
      params.period = 'Month'
      params.licenseType = 'Perpetual'
    } else {
      params = action.params
    }
    const months = yield call(() =>
      axios.get('licenses/dashboard/activated', { params }).then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.DAS_MONTH).SUCCESS,
      payload: { months }
    })
  } catch (err) {
    yield put({ type: generateActions(types.DAS_MONTH).FAILURE })
    Error(err)
  }
}

export function* getDasGenerateSaga(action: any) {
  try {
    let params: any = {}
    let productName: any[] = []
    if (!action.params) {
      params.period = 'Month'
      params.licenseType = 'Perpetual'
      productName = yield call(() => axios.get('products/names').then((res) => res.data.data))
    } else {
      params = action.params
    }
    const generates = yield call(() =>
      axios.get('licenses/dashboard/generated', { params }).then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.DAS_GENERATE).SUCCESS,
      payload: productName.length !== 0 ? { generates, productName } : { generates }
    })
  } catch (err) {
    yield put({ type: generateActions(types.DAS_GENERATE).FAILURE })
    Error(err)
  }
}
