export const mappingObjReleaseTable = {
  'Creation Date': 'created',
  Product: 'product',
  Version: 'version',
  Platform: 'platform',
  Files: 'totalFiles',
  'Release Note': 'locale',
  Published: 'published',
  Delete_Action: 'action'
}

export const tableHeaderReleaseList = [
  'Creation Date',
  'Product',
  'Version',
  'Platform',
  'Files',
  'Release Note',
  'Published',
  'Delete_Action'
]

export const UPLOAD_RELEASE_FILES_STATUS = {
  SUCCESSFUL: 'Successful',
  UPLOADING: 'Uploading',
  FAILED: 'Failed'
}
