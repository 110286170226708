class Environment {
  apiUrl: any = ''
  redirectUri = ''
  postLogoutRedirectUri = ''
  constructor() {
    if (process.env.NODE_ENV === 'development') {
      this.apiUrl = process.env.REACT_APP_API_URL_TEST
      // this.apiUrl = 'http://localhost:3000/rest/v1/'
    } else {
      switch (window.location.origin) {
        case process.env.REACT_APP_REDIRECT_URL_DOMAIN:
          this.apiUrl = process.env.REACT_APP_API_URL
          break
        case process.env.REACT_APP_REDIRECT_URL_TEST_DOMAIN:
          this.apiUrl = process.env.REACT_APP_API_URL_TEST
          break
        case process.env.REACT_APP_REDIRECT_URL_STAGING_DOMAIN:
          this.apiUrl = process.env.REACT_APP_API_URL_STAGING
          break
        case process.env.REACT_APP_REDIRECT_URL_UAT_DOMAIN:
          this.apiUrl = process.env.REACT_APP_API_URL_UAT
          break
        case process.env.REACT_APP_REDIRECT_URL_PROD_DOMAIN:
          this.apiUrl = process.env.REACT_APP_API_URL_PROD
          break
        default:
          break
      }
    }
    this.redirectUri = window.location.origin
    this.postLogoutRedirectUri = window.location.origin
  }
}

export default Environment
