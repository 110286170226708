import { call, put } from 'redux-saga/effects'
import { addNewUser } from '../apiCall'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'

export function* addNewUserSaga() {
  try {
    const user = yield JSON.parse(localStorage.getItem('user') as any)
    const userInfo = yield JSON.parse(localStorage.getItem('userInfo') as any)
    const userInfoNew = yield call(addNewUser, user.accessToken, {
      displayName: userInfo.displayName ? userInfo.displayName : '',
      email: userInfo.email ? userInfo.email : ''
    })
    if (userInfoNew.error === null) {
      yield localStorage.setItem('userInfo', JSON.stringify(userInfoNew.data))
      yield put({
        type: generateActions(types.LOGIN).SUCCESS,
        payload: { userInfo: userInfoNew.data }
      })
    } else {
      yield put({ type: types.CLEAR_USER_INFO })
    }
  } catch (error) {
    yield console.log(error)
  }
}
