import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import { changeLanguageSaga, getRoleSaga, updateUserSaga, userMnSaga } from './userManagementSaga'

const userManagement = [
  takeLatest(generateActions(types.DATA_USERS).REQUEST, userMnSaga),
  takeLatest(generateActions(types.ROLES).REQUEST, getRoleSaga),
  takeLatest(generateActions(types.UPDATE_USER).REQUEST, updateUserSaga),
  takeLatest(generateActions(types.CHANGE_LANGUAGE).REQUEST, changeLanguageSaga)
]

export default userManagement
