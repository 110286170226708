import React from 'react'
import LangConfig from '../../../assets/images/icon/global.svg'
import './LanguageChooser.scss'
import Dropdown from 'react-bootstrap/Dropdown'
import { useTranslation } from 'react-i18next'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../../redux/actions/actions'
import { store } from '../../../index'

interface LanguageChooserProps {
  varient: string
  clicked: () => void
}

const LanguageChooser: React.FC<LanguageChooserProps> = ({ varient, clicked }) => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (lng: string) => {
    const userInfo = localStorage.getItem('userInfo')
    i18n.changeLanguage(lng)
    if (userInfo && JSON.parse(userInfo).language) {
      store.dispatch({
        type: generateActions(types.CHANGE_LANGUAGE).REQUEST,
        body: { lang: lng, userId: JSON.parse(userInfo).id }
      })
    }
  }

  return (
    <div className={'language-config'}>
      <Dropdown drop={'left'}>
        <Dropdown.Toggle variant={varient}>
          {' '}
          <img src={LangConfig} alt="languageChooser" onClick={clicked}></img>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>
            <div className={'language-text'}>{t('Language')}</div>{' '}
            <img src={LangConfig} alt="languageChooser"></img>
          </Dropdown.Header>
          <Dropdown.Item as="button" onClick={() => changeLanguage('en')}>
            English
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => changeLanguage('kr')}>
            한국어
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default LanguageChooser
