import { call, put } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { parseUserInfo } from '../../../utils/helper'
import { getUsbSn } from '../../actions/snsListAction'

const applicationZip = 'application/zip'

interface Action1 {
  type: string
  licensePackageId: number
}

interface Action2 {
  type: string
  keys: string[]
}

/* get data for USB-SN pop-up (license package detail) */
const getLicensePackage = (licensePackageId: number) => {
  return axios.get(`license-packages/${licensePackageId}`).then((res) => res.data)
}

/* Change status -> deactivated for selected Licenses Key*/
const setDeactiveLicensePackage = (keys: string[]) => {
  const body = {
    keys: keys
  }
  return axios.put('licenses/deactivate', JSON.stringify(body)).then((res) => res.data)
}

/* Change status -> deactivated for selected Licenses Key*/
const changeCCULicensePackage = (key: string, data: any) => {
  return axios.put(`licenses/${key}`, data).then((res) => res.data)
}

/* get SAP code list*/
const getSapCode = () => {
  return axios.get('package-templates/sapCodes').then((res) => res.data)
}

// function buildFileName (type: string, usbSn: string | undefined, userInfo: any, YY: string, MM: string, dd: string, time: string) {
//   let fileName = ''
//   if (type === 'txt') {
//     fileName = `${usbSn ? usbSn : 'demo'}.txt`
//   } else if (type === 'csv') {
//     fileName = `USBSN_${JSON.parse(userInfo).id}_${usbSn ? usbSn : 'demo'}_${
//       YY + MM + dd + time
//     }.csv`
//   } else {
//     fileName = `LMP_${YY + MM + dd}`
//   }
//   return fileName
// }

/* Export file*/
const downLoadFile = (res: any, type: string, usbSn?: string) => {
  let blob
  if (type === applicationZip) {
    blob = new Blob([res.data], {
      type: applicationZip
    })
  } else {
    blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
      type: 'text/csv'
    })
  }
  const csvURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  const fileName = res.headers['x-suggested-filename']
  tempLink.setAttribute('download', fileName)

  if (window.navigator.msSaveOrOpenBlob as any) {
    window.navigator.msSaveBlob(
      blob,
      type !== 'txt' && type !== 'csv' ? `${fileName}.zip` : fileName
    )
  } else {
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }
}

export function* licensePackageSaga(action: Action1) {
  try {
    const licensePackage = yield call(() =>
      getLicensePackage(action.licensePackageId).then((res: any) => res)
    )
    yield put({
      type: generateActions(types.LICENSE_PACKAGES).SUCCESS,
      payload: { licensePackage: licensePackage.data }
    })
  } catch (error) {
    Error(error)
  }
}

export function* licenseKeyDetailSaga(action: { type: string; key: string }) {
  try {
    const licenseKeyDetail = yield call(() =>
      axios.get(`licenses/${action.key}`).then((res) => res.data)
    )
    yield put({
      type: generateActions(types.LICENSE_KEY_DETAIL).SUCCESS,
      payload: { licenseKeyDetail: licenseKeyDetail.data }
    })
  } catch (error) {
    const errResponse = {
      msg: error.response?.data?.error?.message,
      status: 'error',
      field: 'getDetail'
    }
    yield put({
      type: generateActions(types.LICENSE_KEY_DETAIL).FAILURE,
      payload: { responseStatus: { ...errResponse }, errResponse: errResponse }
    })
    Error(error)
  }
}

export function* changeStatusLPSaga(action: Action2) {
  try {
    const listLicense = yield call(() =>
      setDeactiveLicensePackage(action.keys).then((res: any) => res.data)
    )
    const keyMapData: any = {}
    listLicense?.forEach((el: any) => {
      keyMapData[el.key] = el
    })
    const successResponse = {
      msg: 'deactivated_successfully',
      status: 'success',
      field: 'deactivated'
    }
    yield put({
      type: generateActions(types.LICENSE_PACKAGES_STATUS).SUCCESS,
      keys: action.keys,
      payload: { responseStatus: { ...successResponse } },
      keyMapData: keyMapData
    })
  } catch (error) {
    const errResponse = {
      msg:
        error.response?.data?.error?.moreInfo === 'cryptlex.license.deactivated.update.failed'
          ? 'deactivated_update_fail'
          : 'deactivated_fail',
      status: 'error',
      field: 'deactivated'
    }
    yield put({
      type: generateActions(types.LICENSE_PACKAGES_STATUS).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(error)
  }
}

export function* changeCCULPSaga(action: any) {
  try {
    const licenseKeyDetailUpdated = yield call(() =>
      changeCCULicensePackage(action.key, action.data).then((res: any) => res)
    )
    const successResponse = {
      msg: 'updated_successfully',
      status: 'success',
      field: 'info'
    }
    yield put({
      type: generateActions(types.LICENSE_PACKAGES_DATA).SUCCESS,
      key: action.key,
      data: action.data,
      licenseKeyDetailUpdated: licenseKeyDetailUpdated.data,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'updated_fail',
      status: 'error',
      field: 'info'
    }
    yield put({
      type: generateActions(types.LICENSE_PACKAGES_DATA).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* getSapCodeSaga(action: any) {
  try {
    const sapCode = yield call(() => getSapCode().then((res: any) => res))
    yield put({
      type: generateActions(types.SAP_CODE).SUCCESS,
      payload: { sapCode: sapCode.data }
    })
  } catch (err) {
    Error(err)
  }
}

/* getValue Dropdown list - SAP Order, USB S/N, Clinic, Machine S/N, SAP Code*/
const getValueDropdown = () => {
  return axios.get('licenses/search-resources').then((res) => res.data)
}

export function* getValueDropdownSaga(action: any) {
  try {
    const dropdownValue = yield call(() => getValueDropdown().then((res: any) => res))
    yield put({
      type: generateActions(types.DROPDOWN).SUCCESS,
      payload: {
        sapCode: dropdownValue.data.sapCodes,
        sapOrder: dropdownValue.data.sapOrders,
        usbSn: dropdownValue.data.usbSNs,
        machineSn: dropdownValue.data.machineSNs,
        clinicName: dropdownValue.data.clinicNames
      }
    })
  } catch (err) {
    Error(err)
  }
}

export function* exportLKSaga(action: any) {
  const config: any = {}
  let END_POINT = ''
  let body: any = {}
  if (action.id) {
    // export in Usb S/N view
    END_POINT = `license-packages/${action.id}/export`
    body.licensePackageId = action.id
  } else {
    // export in License Key List view
    const userInfo = yield localStorage.getItem('userInfo') as any
    END_POINT = 'licenses/export'
    config.responseType = 'arraybuffer' // to download file zip from sever
    body = action.params ? action.params : {}
    if (userInfo && !action.showExpand) {
      body.status = ['0', '1']
    }
    if (body.includeExpired === undefined) {
      body.includeExpired = true
    }
  }
  body.exportType = action.typeFile

  try {
    const res = yield call(() => axios.post(END_POINT, body, config).then((result) => result))
    downLoadFile(
      res,
      action.id && action.id.toString() ? action.typeFile : applicationZip,
      action.usbSn
    )
    yield put({ type: generateActions(types.EXPORT_LK).SUCCESS })
  } catch (err) {
    yield put({ type: generateActions(types.EXPORT_LK).FAILURE })
    Error(err)
  }
}

export function* exportCSVLKSaga(action: any) {
  const config: any = {}
  let END_POINT = ''
  let body: any = {}
  // export in License Key List view
  const userInfo = yield localStorage.getItem('userInfo') as any
  END_POINT = 'licenses/exportCSV'
  config.responseType = 'arraybuffer' // to download file zip from sever
  body = action.params ? action.params : {}
  if (userInfo && !action.showExpand) {
    body.status = ['0', '1']
  }
  if (body.includeExpired === undefined) {
    body.includeExpired = true
  }
  body.exportType = action.typeFile

  try {
    const res = yield call(() => axios.post(END_POINT, body, config).then((result) => result))
    downLoadFile(res, 'csv', action.usbSn)
    yield put({ type: generateActions(types.EXPORT_CSV_LK).SUCCESS })
  } catch (err) {
    yield put({ type: generateActions(types.EXPORT_CSV_LK).FAILURE })
    Error(err)
  }
}

export function* getTemplateDetailSaga(action: any) {
  try {
    const uri = 'package-templates/search'
    const params = { params: { sapCode: action.sapCode } }
    const res = yield call(() => axios.get(uri, params).then((result) => result.data))
    yield put({
      type: generateActions(types.TEMPLATE_DETAIL).SUCCESS,
      payload: { templateDetail: { ...res.data } }
    })
  } catch (err) {
    yield put({ type: generateActions(types.TEMPLATE_DETAIL).FAILURE })
    Error(err)
  }
}

export function* generateLKSaga(action: any) {
  try {
    yield call(() => axios.post('license-packages/generate', action.data).then((res) => res))
  } catch (err) {
    const errResponse = {
      msg:
        err.response.status === 404
          ? 'The package template is no longer available'
          : 'Number of USB S/N exceeds monthly limitation',
      status: 'error',
      field: 'generateLK'
    }
    yield put({
      type: generateActions(types.GENERATE_LK).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* renewLKSaga(action: any) {
  try {
    const licenseKeyDetail = yield call(() =>
      axios.put(`licenses/${action.key}/renew`).then((res) => res.data)
    )
    const successResponse = {
      msg: 'renewed_successfully',
      status: 'success',
      field: 'renew'
    }
    yield put({
      type: generateActions(types.RENEW_LK).SUCCESS,
      payload: { responseStatus: { ...successResponse } },
      licenseKeyDetail: Object.assign({}, licenseKeyDetail.data, { updatedUser: parseUserInfo() })
    })
  } catch (err) {
    const errResponse = {
      msg: 'renewed_fail',
      status: 'error',
      field: 'renew'
    }
    yield put({
      type: generateActions(types.RENEW_LK).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* deleteLicensePackageSaga(action: any) {
  try {
    yield call(() =>
      axios.delete(`license-packages/${action.licensePackageId}`).then((res) => res.data)
    )

    const successResponse = {
      msg: 'Delete license package successful',
      status: 'success',
      field: 'delete'
    }

    yield put({
      type: generateActions(types.DELETE_LICENSE_PACKAGE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg:
        err.response?.data?.error?.moreInfo === 'licenses_activated_different_clinics'
          ? 'Delete license package different clinic error'
          : 'Delete license package failed',
      status: 'error',
      field: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_LICENSE_PACKAGE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const getAllUsbSnAPI = () => axios.get(`license-packages/getAllUsbSn`).then(res => res.data)

export function* getAllUsbSnSaga() {
  try {
    const allUsbSN = yield call(getAllUsbSnAPI)

    yield put({
      type: generateActions(types.USB_SN).SUCCESS,
      payload: allUsbSN.data
    })
  } catch (error) {
    const errResponse = {
      msg: 'getAllUsbSn_fail',
      status: 'error',
      field: 'getAllUsbSn'
    }

    yield put({
      type: generateActions(types.USB_SN).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(error)
  }
}

const updateUsbSnAPI = (id: number, usbSn: string) => axios.patch(`license-packages/${id}/usbSn/${usbSn}`).then(res => res.data)

export function* updateUsbSnSaga(action: any) {
  const { payload } = action
  const { usbSn, id } = payload
  
  try {
    const response = yield call(updateUsbSnAPI, id, usbSn)

    const successResponse = {
      msg: 'The USB S/N has been update successfully',
      status: 'success',
      field: 'updateUsbSn'
    }

    yield put({
      type: generateActions(types.UPDATE_USB_SN).SUCCESS,
      payload: { responseStatus: { ...successResponse }, licensePackage: response.data }
    })

    yield put(getUsbSn())
  } catch (error) {
    const errResponse = {
      msg: 'Failed to update the USB S/N',
      status: 'error',
      field: 'info'
    }

    yield put({
      type: generateActions(types.UPDATE_USB_SN).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(error)
  }
}

const checkExistedUsbSnAPI = (usbSn: string) => axios.post(`license-packages/usbSn/${usbSn}`).then(res => res.data)

export function* checkExistedUsbSnSaga(action: any) {
  const { payload, onSuccess, onFailure } = action
  const { usbSn } = payload

  try {
    const response = yield call(checkExistedUsbSnAPI, usbSn)
    console.log(response)

    yield call(onSuccess, response.data.isExisted)
  } catch (error) {
    yield call(onFailure)
    Error(error)
  }
}
