import React, { useState } from 'react'
import './UserProfile.scss'
import Dropdown from 'react-bootstrap/Dropdown'
import Accordion from 'react-bootstrap/Accordion'
import downIcon from '../../../assets/images/icon/keyboard_arrow_down_24px.svg'
import { useTranslation } from 'react-i18next'

const UserProfile = (props: any) => {
  const { t } = useTranslation()
  const [flag, setFlag] = useState(true)
  return (
    <div className="UserProfile">
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="UserInfo">
          <div className="UserIcon">
            {props.userInfo?.displayName ? props.userInfo?.displayName[0] : ''}
          </div>
          {/* <div className='Name'>{props.userInfo?.displayName}</div> */}
        </Dropdown.Toggle>

        <Dropdown.Menu className="UserInfoDetail">
          <Dropdown.ItemText>
            <Accordion>
              <Accordion.Toggle as="span" eventKey="0" onClick={() => setFlag(!flag)}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer'
                  }}>
                  <span style={{ fontWeight: 'bold', display: 'flex' }}>
                    {/* <img src={info} alt="info" style={{width:' 16px', filter: 'invert(1)'}}/> */}
                    {t('User Infomation')}
                  </span>
                  <img
                    src={downIcon}
                    alt=""
                    style={
                      flag
                        ? { width: '10px', height: '10px' }
                        : {
                            transform: 'rotate(180deg)',
                            width: '10px',
                            height: '10px'
                          }
                    }
                  />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <>
                  {/* <p>User Name: <br /> <span>{props.userInfo?.displayName}</span></p>
                  <p>Email: <br /> <span>{props.userInfo?.email}</span></p> */}
                  <p>
                    <span>{props.userInfo?.displayName}</span>
                  </p>
                  <p>
                    <span>{props.userInfo?.email}</span>
                  </p>
                </>
              </Accordion.Collapse>
            </Accordion>
          </Dropdown.ItemText>

          <Dropdown.Item as="button" onClick={() => props.logout()}>
            {/* <img src={icon} alt="logout" style={{filter: 'invert(1)', transform: 'rotate(180deg)'}}/> */}
            <span> {t('Logout')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default UserProfile
