import AuthService from '../../services/auth.service'
import GraphService from '../../services/graph.service'

const authService = new AuthService()
const graphService = new GraphService()

// export const getToken = () => {
//   return authService.getToken().then((res: any) => res);
// }

export const logout = () => {
  return authService.logout()
}

export const getUserInfo = (token: string) => {
  return graphService.getUserInfo(token).then((res: any) => res)
}

export const addNewUser = (token: string, userInfo: any) => {
  return graphService.addNewUser(token, userInfo).then((res: any) => res)
}
