import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import { snsListSaga, licenseListSaga } from './snsListSaga'
import {
  changeCCULPSaga,
  changeStatusLPSaga,
  exportLKSaga,
  exportCSVLKSaga,
  generateLKSaga,
  getSapCodeSaga,
  getValueDropdownSaga,
  getTemplateDetailSaga,
  licenseKeyDetailSaga,
  licensePackageSaga,
  renewLKSaga,
  deleteLicensePackageSaga,
  getAllUsbSnSaga,
  updateUsbSnSaga,
  checkExistedUsbSnSaga
} from './licensePackageSaga'

const snsList = [
  takeLatest(generateActions(types.SNSLIST).REQUEST, snsListSaga),
  takeLatest(generateActions(types.LICENSE_PACKAGES).REQUEST, licensePackageSaga),
  takeLatest(generateActions(types.LICENSE_PACKAGES_STATUS).REQUEST, changeStatusLPSaga),
  takeLatest(generateActions(types.LICENSE_PACKAGES_DATA).REQUEST, changeCCULPSaga),
  takeLatest(generateActions(types.SAP_CODE).REQUEST, getSapCodeSaga),
  takeLatest(generateActions(types.DROPDOWN).REQUEST, getValueDropdownSaga),
  takeLatest(generateActions(types.EXPORT_LK).REQUEST, exportLKSaga),
  takeLatest(generateActions(types.EXPORT_CSV_LK).REQUEST, exportCSVLKSaga),
  takeLatest(generateActions(types.TEMPLATE_DETAIL).REQUEST, getTemplateDetailSaga),
  takeLatest(generateActions(types.GENERATE_LK).REQUEST, generateLKSaga),
  takeLatest(generateActions(types.LICENSE_KEY_DETAIL).REQUEST, licenseKeyDetailSaga),
  takeLatest(generateActions(types.RENEW_LK).REQUEST, renewLKSaga),
  takeLatest(generateActions(types.LICENSELIST).REQUEST, licenseListSaga),
  takeLatest(generateActions(types.DELETE_LICENSE_PACKAGE).REQUEST, deleteLicensePackageSaga),
  takeLatest(generateActions(types.USB_SN).REQUEST, getAllUsbSnSaga),
  takeLatest(generateActions(types.UPDATE_USB_SN).REQUEST, updateUsbSnSaga),
  takeLatest(generateActions(types.CHECK_EXISTED_USB_SN).REQUEST, checkExistedUsbSnSaga)
]

export default snsList
