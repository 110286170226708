import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'products'

interface Action {
  type: string
  params: {
    page: number
    size: number
    objectParams: any
  }
}

const getProductName = () => {
  return axios.get(`${ENDPOINTS}/names`).then((res) => res.data)
}

const getProductInfos = () => {
  return axios.get(ENDPOINTS).then((res) => res.data)
}

// get list name for filter
export function* productNameListSaga() {
  try {
    const productName = yield call(() => getProductName().then((res: any) => res))
    yield put({
      type: generateActions(types.PRODUCTLIST).SUCCESS,
      payload: { productName: productName.data }
    })
  } catch (error) {
    Error(error)
  }
}

//get all product infos
export function* productListSaga() {
  try {
    const product = yield call(() => getProductInfos().then((res: any) => res))
    yield put({
      type: generateActions(types.PRODUCTSINFO).SUCCESS,
      payload: { productInfos: product.data }
    })
  } catch (error) {
    Error(error)
  }
}

const getCryptlexPoliciesList = () => {
  return axios.get('license-policy/cryptlex-policies').then((res) => res.data)
}

//get all cryptlex policies
export function* cryptlexPoliciesListSaga() {
  try {
    const policies = yield call(() => getCryptlexPoliciesList().then((res: any) => res))
    yield put({
      type: generateActions(types.CRYPTLEXPOLICIES).SUCCESS,
      payload: { cryptlexPolicies: policies.data }
    })
  } catch (error) {
    Error(error)
  }
}

const getCryptlexProductsList = () => {
  return axios.get(`${ENDPOINTS}/cryptlex-products`).then((res) => res.data)
}

//get all cryptlex products
export function* cryptlexProductsListSaga() {
  try {
    const product = yield call(() => getCryptlexProductsList().then((res: any) => res))
    yield put({
      type: generateActions(types.CRYPTLEXPRODUCTS).SUCCESS,
      payload: { cryptlexProducts: product.data }
    })
  } catch (error) {
    Error(error)
  }
}

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    params: {
      page: pageR,
      size,
      ...objectParams
    }
  }
  return axios.get('products', config as AxiosRequestConfig).then((res) => res.data)
}

export function* productListSagaByPage(action: Action) {
  try {
    if (action.params.objectParams) {
      Object.keys(action.params.objectParams).forEach((ele: any) => {
        if (typeof action.params.objectParams[ele] == 'string') {
          action.params.objectParams[ele] = action.params.objectParams[ele].trim()
        }
      })
    }
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.PRODUCTSINFODATA).SUCCESS,
      payload: { ...data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.PRODUCTSINFODATA).FAILURE })
    Error(err)
  }
}

type Product = {
  caProductId: string
  name: string
  features: FeaturesObj[]
}

interface FeaturesObj {
  caLicensePolicy: string
  licensePolicyName: string
  licenseType: number
  keys: number
}

const createProduct = (data: Product) => {
  return axios.post(ENDPOINTS, data)
}

export function* createProductSaga(action: any) {
  try {
    yield call(() => createProduct(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'A product has been added successfully',
      status: 'success',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_NEW_PRODUCT).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to add a product',
      status: 'error',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_NEW_PRODUCT).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const updateProduct = (data: Product) => {
  return axios.put(ENDPOINTS, data)
}

export function* updateProductSaga(action: any) {
  try {
    yield call(() => updateProduct(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'The product has been updated successfully',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_PRODUCT).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to update the product',
      status: 'error',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_PRODUCT).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const deleteProduct = (id: number) => {
  return axios.delete(`${ENDPOINTS}/${id}`)
}

export function* deleteProductSaga(action: any) {
  try {
    yield call(() => deleteProduct(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'The product has been deleted successfully',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_PRODUCT).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to delete the product',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_PRODUCT).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}
