import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const autoDownloadReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.RC_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.RC_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.RC_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })
    case generateActions(types.ADD_RC).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.ADD_RC).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ADD_RC).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.RC_DETAILS).REQUEST:
      return Object.assign({}, state, { rcDetails: null })
    case generateActions(types.RC_DETAILS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RC).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.UPDATE_RC).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RC).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RC).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.DELETE_RC).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RC).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.VERSION_AUTO_LIST).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.VERSION_PUBLISHED).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CLEAR_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    default:
      return state
  }
}

export default autoDownloadReducer
