import { checkAuth } from './checkAuth'
import { fork } from 'redux-saga/effects'
// import { reAuthenSaga } from './reAuthenSaga';

const authen = [
  fork(checkAuth)
  // takeLatest('REAUTHEN', reAuthenSaga),
]

export default authen
