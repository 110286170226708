import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'clinics'

interface Action {
  type: string
  params: {
    page: number
    size: number
    objectParams: any
  }
}

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    page: pageR,
    size,
    ...objectParams
  }
  return axios.post(`${ENDPOINTS}/findAll`, JSON.stringify(config)).then((res) => res.data)
}

export function* clinicListSagaByPage(action: Action) {
  try {
    if (action.params.objectParams) {
      Object.keys(action.params.objectParams).forEach((ele: any) => {
        if (typeof action.params.objectParams[ele] == 'string') {
          action.params.objectParams[ele] = action.params.objectParams[ele].trim()
        }
      })
    }
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.CLINIC_LIST).SUCCESS,
      payload: { ...data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.CLINIC_LIST).FAILURE })
    Error(err)
  }
}

export function* getScheduleSaga() {
  try {
    const schedule = yield call(() =>
      axios.get('schedule-jobs/sync-clinic-jobs').then((res) => res.data.data)
    )
    yield put({
      type: generateActions(types.SCHEDULE).SUCCESS,
      payload: { schedule }
    })
  } catch (err) {
    yield put({ type: generateActions(types.SCHEDULE).FAILURE })
    Error(err)
  }
}

export function* updateScheduleSaga(action: any) {
  try {
    yield call(() =>
      axios.post('schedule-jobs/sync-clinic-jobs', action.data).then((res) => res.data.data)
    )
    const successResponse = {
      msg: 'The scheduled task has been updated successfully',
      status: 'success',
      field: 'schedule'
    }
    yield put({
      type: generateActions(types.UPDATE_SCHEDULE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to update the scheduled task',
      status: 'error',
      field: 'schedule'
    }
    yield put({
      type: generateActions(types.UPDATE_SCHEDULE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const syncClinic = (objectParams?: object) => {
  const config = {
    params: {
      ...objectParams
    }
  }
  return axios.get(`${ENDPOINTS}/sync`, config as AxiosRequestConfig).then((res) => res.data)
}

export function* syncClinicSaga(action: Action) {
  try {
    const data = yield call(() => syncClinic(action.params.objectParams).then((res) => res.data))
    const successResponse = {
      msg: 'Clinic information has been synced successfully',
      status: 'success',
      field: 'sync'
    }
    yield put({
      type: generateActions(types.SYNC_CLINIC).SUCCESS,
      payload: { responseStatus: { ...successResponse }, ...data }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to sync clinic information',
      status: 'error',
      field: 'sync'
    }
    yield put({
      type: generateActions(types.SYNC_CLINIC).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

/* getValue Dropdown list - SAP Order, USB S/N, Clinic, Machine S/N, SAP Code*/
const getValueDropdown = () => {
  return axios.get('licenses/search-resources').then((res) => res.data)
}

export function* getValueDropdownSaga(action: any) {
  try {
    const dropdownValue = yield call(() => getValueDropdown().then((res: any) => res))
    yield put({
      type: generateActions(types.DROPDOWN).SUCCESS,
      payload: {
        sapCode: dropdownValue.data.sapCodes,
        sapOrder: dropdownValue.data.sapOrders,
        usbSn: dropdownValue.data.usbSNs,
        machineSn: dropdownValue.data.machineSNs,
        clinicName: dropdownValue.data.clinicNames
      }
    })
  } catch (err) {
    Error(err)
  }
}
