import React from 'react'
import classes from './Breadcumb.module.scss'
import { headers } from '../../configs/constants/pageHeader'
import { useTranslation } from 'react-i18next'
import { store } from '../../index'

const BreadCumb = (props: any) => {
  const { t } = useTranslation()
  const mainHeader = (props.history.location.pathname as string).split('/')[1]

  return (
    <div className={classes.Breadcumb}>
      <ul className={classes.Tabs}>
        {(headers as any)[mainHeader]?.map((tab: any, index: number) => {
          return tab.role.includes(store.getState().loginReducer.userInfo.role) ? (
            <div key={index.toString()}>
              <li style={index === 0 ? { marginLeft: '0' } : {}}>
                <a
                  style={mainHeader === tab.link.split('/')[1] ? { color: 'red' } : {}}
                  href={tab.link}>
                  {t(tab.name)}
                </a>
              </li>
            </div>
          ) : null
        })}
      </ul>
    </div>
  )
}

export default BreadCumb
