import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'

const getDeletedLicensePackageAPI = () => {
  return axios.get('/license-packages/deleted').then(res => res.data)
}

export function* getDeletedLicensePackageSaga(action: any) {
  try {
    const data = yield call(getDeletedLicensePackageAPI)

    yield put({
      type: generateActions(types.DELETED_LINCESE_PACKAGE).SUCCESS,
      payload: {
        deletedLicensePackage: data.data
      }
    })
  } catch (error) {
    yield put({ type: generateActions(types.DELETED_LINCESE_PACKAGE).FAILURE })
    Error(error)
  }
}
