import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const clinicListReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.CLINIC_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })

    case generateActions(types.CLINIC_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.SCHEDULE).REQUEST:
      return Object.assign({}, state, { schedule: null })

    case generateActions(types.SCHEDULE).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.UPDATE_SCHEDULE).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.UPDATE_SCHEDULE).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.CLEAR_ERROR_CLINIC).REQUEST:
      return Object.assign({}, state, { responseStatus: null })

    case generateActions(types.SYNC_CLINIC).REQUEST:
      return Object.assign({}, state, { isLoading: true })

    case generateActions(types.SYNC_CLINIC).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.SYNC_CLINIC).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.DROPDOWN).REQUEST:
      return Object.assign({}, state, {
        sapCode: null,
        sapOrder: null,
        usbSn: null,
        machineSn: null,
        clinicName: null
      })

    case generateActions(types.DROPDOWN).SUCCESS:
      return Object.assign({}, state, action.payload)

    default:
      return state
  }
}

export default clinicListReducer
