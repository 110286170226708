import { put } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'

export function* checkAuth() {
  try {
    const userOld = yield localStorage.getItem('user')
    const userInfoOld = yield localStorage.getItem('userInfo')

    if (userOld && userInfoOld) {
      yield put({
        type: generateActions(types.LOGIN).SUCCESS,
        payload: {
          ...JSON.parse(userOld),
          userInfo: { ...JSON.parse(userInfoOld) }
        }
      })
    }
    // Dont need to call this action becasue, in login saga handled it
    // else {
    //   yield put({ type: generateActions(types.LOGIN).REQUEST })
    // }
  } catch (error) { }
}
