import React from 'react'
import './Button.scss'
import { Button } from 'react-bootstrap'
interface ButtonType {
  variant: string //success | outline-secondary | .....
  attribute?: {}
  className?: string
  onClickHandler?: (() => void) | void | Function | any
  style?: {}
  type?: string
  disabled?: boolean
}
const button: React.FC<ButtonType> = ({
  variant,
  attribute,
  className,
  onClickHandler,
  style,
  type,
  children,
  disabled
}) => {
  return (
    <Button
      className={className}
      variant={variant}
      {...attribute}
      onClick={onClickHandler}
      style={style}
      type={type}
      disabled={disabled}>
      {children}
    </Button>
  )
}

export default button
