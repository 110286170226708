import Login from '../containers/Login/Login'
import asyncComponent from '../utils/hoc/asyncComponent'

const numberTwo = 2
const numberThree = 3
const numberFour = 4
interface RouterProps {
  path: string
  component: any
  needAuthenticate: boolean
  exact?: boolean
  roleAllow?: number[]
}

const routes: RouterProps[] = [
  {
    path: '/login-error',
    component: Login,
    needAuthenticate: false
  },
  {
    path: '/dashboard',
    component: asyncComponent(() =>
      import('../components/Dashboard/Dashboad').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberFour]
  },
  {
    path: '/user-management',
    component: asyncComponent(() =>
      import('../containers/UserManagement/UserManagement').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberFour]
  },
  {
    path: '/license-key-list',
    component: asyncComponent(() =>
      import('../containers/SNSList/SNSList').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/clinic-list',
    component: asyncComponent(() =>
      import('../containers/ClinicList/ClinicListContent').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/license-package-template',
    component: asyncComponent(() =>
      import('../components/GenerateLicenseKeys/LicensePackageTemplate').then(
        (module) => module.default
      )
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberThree, numberFour]
  },
  {
    path: '/release-list',
    component: asyncComponent(() =>
      import('../components/ReleaseList/ReleaseList').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/release-note',
    component: asyncComponent(() =>
      import('../components/ReleaseNote/ReleaseNoteManagement').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/notification',
    component: asyncComponent(() =>
      import('../components/Notification/NotificationManagement').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/locale-setting',
    component: asyncComponent(() =>
      import('../components/LocaleSetting/LocaleSetting').then((module) => module.default)
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberTwo, numberThree, numberFour]
  },
  {
    path: '/auto-download-management',
    component: asyncComponent(() =>
      import('../components/AutoDownloadManagement/AutoDownloadManagement').then(
        (module) => module.default
      )
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberThree, numberFour]
  },
  {
    path: '/sales-area-management',
    component: asyncComponent(() =>
      import('../components/SalesAreaManagement/SalesAreaManagement').then(
        (module) => module.default
      )
    ),
    needAuthenticate: true,
    exact: true,
    roleAllow: [numberThree, numberFour]
  },
  {
    path: '/audit-logs',
    component: asyncComponent(() =>
      import('../components/AuditLog/AuditLog').then(
        (module) => module.default
      )
    ),
    needAuthenticate: true,
    exact: true, 
    roleAllow: [numberFour]
  }
]

export default routes
