import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const releaseNoteReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.RELEASE_NOTE_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.RELEASE_NOTE_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.RELEASE_NOTE_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })
    case generateActions(types.ADD_RELEASE_NOTE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.ADD_RELEASE_NOTE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.ADD_RELEASE_NOTE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.RELEASE_NOTE_DETAILS).REQUEST:
      return Object.assign({}, state, { releaseNoteDetails: null })
    case generateActions(types.RELEASE_NOTE_DETAILS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RELEASE_NOTE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.UPDATE_RELEASE_NOTE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_RELEASE_NOTE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RELEASE_NOTE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.DELETE_RELEASE_NOTE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.DELETE_RELEASE_NOTE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.GET_RELEASE_NOTES).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CLEAR_RELEASE_NOTES).REQUEST:
      return Object.assign({}, state, { releaseNotes: { rows: [] } })
    case generateActions(types.CLEAR_RELEASE_NOTE_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    default:
      return state
  }
}

export default releaseNoteReducer
