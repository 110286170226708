import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const auditLogReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.DELETED_LINCESE_PACKAGE).REQUEST:
      return Object.assign({}, state, { deletedLicensePackage: null, isLoading: true })

    case generateActions(types.DELETED_LINCESE_PACKAGE).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.DELETED_LINCESE_PACKAGE).FAILURE:
      return Object.assign({}, state, { deletedLicensePackage: null, isLoading: false })

    default:
      return state
  }
}

export default auditLogReducer
