import * as types from '../../../configs/constants/actionType'
import { takeLatest, fork } from 'redux-saga/effects'
import { intervalGetUserRoleSaga } from './loginSaga'
import { addNewUserSaga } from './addNewUserSaga'
import generateActions from '../../actions/actions'

const login = [
  // takeLatest(generateActions(types.LOGIN).REQUEST, loginSaga),
  takeLatest(generateActions(types.ADD_NEW_USER).REQUEST, addNewUserSaga),
  fork(intervalGetUserRoleSaga)
]

export default login
