import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  salesAreaListSagaByPage,
  createSalesAreaSaga,
  salesAreaDetailsSaga,
  updateSalesAreaSaga,
  deleteSalesAreaSaga,
  getSalesAreasSaga,
  getCreatorsSaga,
  getAllowsSaga,
  getAllAreasSaga,
  getAllAreaForCurrentUserSaga
} from './salesAreaManagementSaga'

const salesAreaManagementSaga = [
  takeLatest(generateActions(types.AREA_LIST).REQUEST, salesAreaListSagaByPage),
  takeLatest(generateActions(types.ADD_SALES_AREA).REQUEST, createSalesAreaSaga),
  takeLatest(generateActions(types.SALES_AREA_DETAILS).REQUEST, salesAreaDetailsSaga),
  takeLatest(generateActions(types.UPDATE_SALES_AREA).REQUEST, updateSalesAreaSaga),
  takeLatest(generateActions(types.DELETE_SALES_AREA).REQUEST, deleteSalesAreaSaga),
  takeLatest(generateActions(types.AREAS_NAME).REQUEST, getSalesAreasSaga),
  takeLatest(generateActions(types.CREATOR_LIST).REQUEST, getCreatorsSaga),
  takeLatest(generateActions(types.ALLOW_LIST).REQUEST, getAllowsSaga),
  takeLatest(generateActions(types.AREAS_ALL).REQUEST, getAllAreasSaga),
  takeLatest(generateActions(types.AREAS_CURRRENT_USER).REQUEST, getAllAreaForCurrentUserSaga)
]

export default salesAreaManagementSaga
