import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as LoginAction from '../../redux/actions/loginAction'
import { Redirect } from 'react-router-dom'
import './login.scss'
import Button from 'react-bootstrap/Button'
import Group_2203 from '../../assets/images/Group_2203.png'
import { withTranslation } from 'react-i18next'

interface LoginSate {}

interface LoginProps {
  user: any
  action: any
  history: any
  t: any
}

// const authService = new AuthService();
class Login extends Component<LoginProps, LoginSate> {
  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.userInfo &&
      !this.props.user.userInfo.role &&
      this.props.user.userInfo.role !== 0
    ) {
      this.props.action.addNewUser()
    }
  }

  handleSignOut = () => {
    this.props.action.logout()
  }

  render() {
    const { t, user } = this.props
    const letgo = this.isLetGo(user)
    return !user.account || !letgo ? (
      <div className="login-page">
        <p className="welcome">{t('Welcome to')}</p>
        <h2 className="title">{t('License Management Portal')}</h2>

        <div className="login-failed">
          <p
            style={{
              fontSize: '14px',
              lineHeight: '24px',
              marginBottom: 'auto',
              color: '#e51e25'
            }}>
            {t('Pending approval')}.<br />
            {t('Please contact Ewoosoft for more information')}
          </p>
          <Button className="btn-logout" onClick={this.handleSignOut}>
            {t('Logout')}
          </Button>
        </div>
        <img className="first" src={Group_2203} alt="login-page" />
      </div>
    ) : (
      <Redirect to="/" />
    )
  }

  private isLetGo(user: any) {
    return user.userInfo && user.userInfo.role && user.userInfo.status === 'Activated'
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.loginReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    action: bindActionCreators(LoginAction, dispatch)
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login) as any)
