import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import rootSaga from './redux/sagas/rootSaga'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import rootReducer from './redux/reducers/rootReducer'
import axios from './configs/axios/axios'
import { handleIntercepter } from './configs/axios/intercepterHandler'
import './i18n'
import ErrorBoundaries from './components/ErrorBoundaries/ErrorBoundaries'

require('dotenv').config()

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// ReduxDevtools extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState: any) {
  const store = createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV !== 'development'
      ? applyMiddleware(sagaMiddleware)
      : composeEnhancers(applyMiddleware(sagaMiddleware))
  )
  sagaMiddleware.run(rootSaga)
  return store
}
const sagaMiddleware = createSagaMiddleware()

// initialState
const initialState = {}

// Create store
export const store = configureStore(initialState)

handleIntercepter(axios)

const appRoot = (
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundaries>
        <App />
      </ErrorBoundaries>
    </BrowserRouter>
  </Provider>
)

const user = localStorage.getItem('user')
if (user) {
  ReactDOM.render(<React.StrictMode>{appRoot}</React.StrictMode>, document.getElementById('root'))
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
