import React from 'react'
import { Route } from 'react-router'
import { store } from '../index'

const PrivateRoute: React.FC<any> = ({ component: Component, roleAllow, ...rest }) => {
  const isAuthen =
    !!store.getState().loginReducer.userInfo &&
    !!roleAllow &&
    roleAllow.includes(store.getState().loginReducer.userInfo.role) &&
    store.getState().loginReducer.userInfo.status === 'Activated'
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthen) {
          return <Component {...props} />
        } else if (!!store.getState().loginReducer.userInfo) {
          window.location.href = '/login-error'
        } else {
          window.location.href = 'loginPage.html'
        }
      }}
    />
  )
}

export default PrivateRoute
