import React, { Fragment } from 'react'
import errorAlert from '../../assets/images/error-alert-modal.png'
import Button from '../Common/Button/Button'
import './ErrorBoundaries.scss'

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryState
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
    this.onClick = this.onClick.bind(this)
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // componentDidCatch(error: any, errorInfo: any) {}

  onClick = function () {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundaries-container">
          <div className="error-boundaries">
            <div className="title">
              <img src={errorAlert} alt="alert" />
              <span>Something went wrong</span>
            </div>
            <span>There's an issue and the page could not be loaded.</span>
            <Button variant="success" onClickHandler={this.onClick}>
              Reload
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
