import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  clinicListSagaByPage,
  getScheduleSaga,
  updateScheduleSaga,
  syncClinicSaga,
  getValueDropdownSaga
} from './clinicListSaga'

const clinicList = [
  takeLatest(generateActions(types.CLINIC_LIST).REQUEST, clinicListSagaByPage),
  takeLatest(generateActions(types.SCHEDULE).REQUEST, getScheduleSaga),
  takeLatest(generateActions(types.UPDATE_SCHEDULE).REQUEST, updateScheduleSaga),
  takeLatest(generateActions(types.SYNC_CLINIC).REQUEST, syncClinicSaga),
  takeLatest(generateActions(types.DROPDOWN).REQUEST, getValueDropdownSaga)
]
export default clinicList
