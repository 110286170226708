import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const localeReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.LOCALE_LIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.LOCALE_LIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.LOCALE_ALL).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })
    case generateActions(types.LOCALE_ALL).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })
    case generateActions(types.LOCALE_LIST).FAILURE:
      return Object.assign({}, state, action.payload, { isLoading: false, count: 0, rows: [] })
    case generateActions(types.LOCALE_DETAILS).REQUEST:
      return Object.assign({}, state, { localeDetails: null })
    case generateActions(types.LOCALE_DETAILS).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_LOCALE).REQUEST:
      return Object.assign({}, state)
    case generateActions(types.UPDATE_LOCALE).SUCCESS:
      return Object.assign({}, state, action.payload)
    case generateActions(types.UPDATE_LOCALE).FAILURE:
      return Object.assign({}, state, action.payload)
    case generateActions(types.CLEAR_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })
    default:
      return state
  }
}

export default localeReducer
