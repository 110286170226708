import React from 'react'
import classes from './MainLogo.module.scss'
import Logo from '../../../assets/images/main-logo.png'

const mainLogo = (props: any) => {
  const reload = (url: string) => {
    window.location.href = url
  }
  return (
    <div className={classes.MainLogo}>
      <img
        style={{ cursor: 'pointer' }}
        onClick={() => reload('/license-key-list')}
        src={Logo}
        alt="main-logo"></img>
    </div>
  )
}

export default mainLogo
