import { put, call, delay } from 'redux-saga/effects'
import { getUserInfo } from '../apiCall'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import i18n from '../../../i18n'
import { TIME_INTERVAL_GET_ROLE } from '../../../configs/constants/login.const'

export function* loginSaga() {
  try {
    // Dont need to call login because login action handled through html file public/login.js
    // const user = yield call(login);
    // yield localStorage.setItem('user', JSON.stringify(user));
    const user = yield JSON.parse(localStorage.getItem('user') as any)
    const userInfo = yield call(getUserInfo, user.accessToken)

    yield localStorage.setItem('userInfo', JSON.stringify(userInfo.data))

    if (userInfo.data.language) {
      i18n.changeLanguage(userInfo.data.language)
    }

    yield put({
      type: generateActions(types.LOGIN).SUCCESS,
      payload: { ...user, userInfo: userInfo.data }
    })
  } catch (error) {
    console.log(error)
    yield put({ type: generateActions(types.LOGIN).FAILURE, error })
  }
}

export function* intervalGetUserRoleSaga() {
  while (true) {
    yield call(loginSaga)
    yield delay(TIME_INTERVAL_GET_ROLE)
  }
}
