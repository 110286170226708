import * as types from '../../configs/constants/actionType'
import generateActions from './actions'

export function login() {
  return {
    type: generateActions(types.LOGIN).REQUEST
  }
}

export function addNewUser() {
  return {
    type: generateActions(types.ADD_NEW_USER).REQUEST
  }
}

export function clearUserInfo() {
  return {
    type: types.CLEAR_USER_INFO
  }
}

export function updateUser(updatedRole: number, status: string, autoUpdateSettingPermission: number) {
  return {
    type: types.UPDATE_USER,
    updatedRole,
    updateStatus: status,
    autoUpdateSettingPermission
  }
}

export function logout() {
  return {
    type: types.LOGOUT
  }
}
