import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'notification'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    params: {
      page: pageR,
      size
    }
  }
  return axios
    .post(`${ENDPOINTS}/findAll`, objectParams, config as AxiosRequestConfig)
    .then((res) => res.data)
}

export function* notificationSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.NOTIFICATION_LIST).SUCCESS,
      payload: { notificationList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.NOTIFICATION_LIST).FAILURE })
    Error(err)
  }
}

const createNotification = (data: any) => {
  return axios.post(`${ENDPOINTS}`, data)
}

export function* createNotificationSaga(action: any) {
  try {
    yield call(() => createNotification(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Notification is created successfully.',
      status: 'success',
      action: 'add'
    }
    yield put({
      type: generateActions(types.ADD_NOTIFICATION).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Notification is NOT created successfully.',
      status: 'error',
      action: 'add',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.ADD_NOTIFICATION).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const getNotificationDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* notificationDetailsSaga(action: any) {
  try {
    const data = yield call(() => getNotificationDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.NOTIFICATION_DETAILS).SUCCESS,
      payload: { notificationDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateNotification = (data: any) => {
  return axios.put(`${ENDPOINTS}/${data.id}`, data)
}

export function* updateNotificationSaga(action: any) {
  try {
    yield call(() => updateNotification(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Notification is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_NOTIFICATION).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Notification is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.UPDATE_NOTIFICATION).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const updateNotificationCountry = (data: any) => {
  return axios.put(`${ENDPOINTS}/${data.id}/updateCountries`, data.countries)
}

export function* updateNotificationCountrySaga(action: any) {
  try {
    yield call(() => updateNotificationCountry(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Notification Country is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_NOTIFICATION_COUNTRY).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Notification Country is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.UPDATE_NOTIFICATION_COUNTRY).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

const deleteNotification = (id: number) => {
  return axios.delete(`${ENDPOINTS}/${id}`)
}

export function* deleteNotificationSaga(action: any) {
  try {
    yield call(() => deleteNotification(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Notification is deleted successfully.',
      status: 'success',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_NOTIFICATION).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Notification is NOT deleted successfully.',
      status: 'error',
      action: 'delete'
    }
    yield put({
      type: generateActions(types.DELETE_NOTIFICATION).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}
