import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'

const dashBoardReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    // case generateActions(types.SNSLIST).REQUEST:
    //   return Object.assign({}, state, {rows: []}, { isLoading: true });
    case generateActions(types.DAS_MONTH).REQUEST:
      return Object.assign({}, state, { isLoadingMonth: true })

    case generateActions(types.DAS_GENERATE).REQUEST:
      return Object.assign({}, state, { isLoadingGenerate: true })

    case generateActions(types.DAS_PRODUCT).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.DAS_MONTH).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingMonth: false })

    case generateActions(types.DAS_GENERATE).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingGenerate: false })

    case generateActions(types.DAS_MONTH).FAILURE:
      return Object.assign({}, state, { isLoadingMonth: false })

    case generateActions(types.DAS_GENERATE).FAILURE:
      return Object.assign({}, state, { isLoadingGenerate: false })

    // case generateActions(types.CLEAR_ERROR).REQUEST:
    //   return Object.assign({}, state, {responseStatus: null});

    default:
      return state
  }
}

export default dashBoardReducer
