import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import {
  createPackageTemplateSaga,
  deletePackageTemplateSaga,
  getLPListSaga,
  updatePackageTemplateSaga,
  getCompanySaga
} from './packageTemplateSaga'
import generateActions from '../../actions/actions'

const packageTemplate = [
  takeLatest(generateActions(types.LP_LIST).REQUEST, getLPListSaga),
  takeLatest(generateActions(types.LP_CREATE).REQUEST, createPackageTemplateSaga),
  takeLatest(generateActions(types.LP_UPDATE).REQUEST, updatePackageTemplateSaga),
  takeLatest(generateActions(types.LP_DELETE).REQUEST, deletePackageTemplateSaga),
  takeLatest(generateActions(types.COMPANY).REQUEST, getCompanySaga)
]
export default packageTemplate
