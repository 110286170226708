import React from 'react'
import './Home.scss'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface HomeProps {
  varient: string
}

const Home: React.FC<HomeProps> = ({ varient }) => {
  const { t } = useTranslation()
  const dropdown = (
    <div className="home">
      {window.location.pathname !== '/license-key-list' ? (
        <NavLink to={'/'}>{t('Home')}</NavLink>
      ) : (
        <a href={'/'}>{t('Home')}</a>
      )}
    </div>
  )

  return <div>{dropdown} </div>
}

export default Home
