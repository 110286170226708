import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'

type PackageTemplate = {
  sapCode: string
  displayName: string
  company: string
  features: ProductDataObj[]
}

interface ProductDataObj {
  productName: string
  licenseType: string
  productFeatureId: number
  maxCcu: number
  activeCcu: number
  selectedMetadata: string
}

// const createTemplatePackage = (data: PackageTemplate) => {
//   return axios.post(ENDPOINTS, data);
// };

// export function* createTemplatePackageSaga(action: any) {
//   try {
//     yield call(() =>
//       createTemplatePackage(action.data).then((res: any) => res)
//     );
//     yield put({
//       type: generateActions(types.ADD_NEW_PACKAGE_TEMPLATE).SUCCESS,
//       key: action.key,
//       data: action.data,
//     });
//   } catch (err) {
//     yield put({
//       type: generateActions(types.ADD_NEW_PACKAGE_TEMPLATE).FAILURE,
//       payload: { error: { ...err.response.data.errors[0] } },
//     });
//     Error(err);
//   }
// }

const ENDPOINTS = '/package-templates'

const getLPList = (page?: number, size?: number, objectBody?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const body = {
    page: pageR,
    size,
    ...objectBody
  }
  return axios.post(`${ENDPOINTS}/search`, JSON.stringify(body)).then((res) => res.data)
}

export function* getLPListSaga(action: any) {
  try {
    if (action.params.objectParams) {
      Object.keys(action.params.objectParams).forEach((ele: any) => {
        if (typeof action.params.objectParams[ele] == 'string') {
          action.params.objectParams[ele] = action.params.objectParams[ele].trim()
        }
      })
    }
    const resData = yield call(() =>
      getLPList(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.LP_LIST).SUCCESS,
      payload: { ...resData.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LP_LIST).FAILURE })
    Error(err)
  }
}

export function* createPackageTemplateSaga(action: any) {
  try {
    if (action.data) {
      Object.keys(action.data).forEach((ele: any) => {
        if (typeof action.data[ele] == 'string') {
          action.data[ele] = action.data[ele].trim()
        }
      })
    }
    yield call(() => axios.post(ENDPOINTS, action.data).then((res) => res.data.data))
    const successResponse = {
      msg: 'A package template has been created successfully',
      status: 'success',
      field: 'create'
    }
    yield put({
      type: generateActions(types.LP_CREATE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to create a package template',
      status: 'error',
      field: 'create'
    }
    yield put({
      type: generateActions(types.LP_CREATE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* updatePackageTemplateSaga(action: any) {
  try {
    yield call(() => axios.patch(`${ENDPOINTS}`, action.data).then((res) => res.data.data))
    const successResponse = {
      msg: 'The package template has been updated successfully',
      status: 'success',
      field: 'updated'
    }
    yield put({
      type: generateActions(types.LP_UPDATE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to update the package template',
      status: 'error',
      field: 'updated'
    }
    yield put({
      type: generateActions(types.LP_UPDATE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

export function* deletePackageTemplateSaga(action: any) {
  try {
    const params = { params: { sapCode: action.sapCode } }
    yield call(() => axios.delete(`${ENDPOINTS}/`, params).then((res) => res.data.data))
    const successResponse = {
      msg: 'The package template has been deleted successfully',
      status: 'success',
      field: 'updated'
    }
    yield put({
      type: generateActions(types.LP_DELETE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Failed to delete package template',
      status: 'error',
      field: 'updated'
    }
    yield put({
      type: generateActions(types.LP_DELETE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}

/* get Company list*/
const getCompany = () => {
  return axios.get('package-templates/companies').then((res) => res.data)
}

export function* getCompanySaga(action: any) {
  try {
    const company = yield call(() => getCompany().then((res: any) => res))
    yield put({
      type: generateActions(types.COMPANY).SUCCESS,
      payload: { company: company.data }
    })
  } catch (err) {
    Error(err)
  }
}
