import React from 'react'
import './Menu.scss'
import { NavLink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { store } from '../../../index'
import { useTranslation } from 'react-i18next'

const numberFour = 4
const numberThree = 3
const numberTwo = 2

interface MenuProps {
  varient: string
  values?: [
    {
      displayValue: string
      values: string
    }
  ]
}
const totalMenu = [
  {
    name: 'Operational Statistics',
    link: '/dashboard',
    role: [numberFour]
  },
  {
    name: 'License Management',
    link: '/license-key-list',
    role: [numberTwo, numberThree, numberFour]
  },
  {
    name: 'Release Management',
    link: '/release-list',
    role: [numberTwo, numberThree, numberFour]
  },
  {
    name: 'Country Management',
    link: '/sales-area-management',
    role: [numberThree, numberFour]
  },
  {
    name: 'Users Management',
    link: '/user-management',
    role: [numberFour]
  }
]
const Menu: React.FC<MenuProps> = ({ varient, values }) => {
  const { t } = useTranslation()
  const dropdown = (
    <div className="menu">
      <Dropdown>
        <Dropdown.Toggle variant={varient}>{t('Menu')}</Dropdown.Toggle>
        <Dropdown.Menu>
          {totalMenu.map((item) => {
            return item.role.includes(store.getState().loginReducer.userInfo.role) ? (
              <Dropdown.Item as="div" key={item.name}>
                {window.location.pathname !== item.link ? (
                  <NavLink to={item.link}>{t(item.name)}</NavLink>
                ) : (
                  <a
                    href={
                      store.getState().loginReducer.userInfo.role === 2 &&
                      item.link === '/release-list'
                        ? '/release-note'
                        : item.link
                    }>
                    {t(item.name)}
                  </a>
                )}
              </Dropdown.Item>
            ) : null
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
  return <div>{dropdown} </div>
}

export default Menu
