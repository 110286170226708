import axios from 'axios'
import Environment from '../environment/environment'

const env = new Environment()
const instance = axios.create({
  baseURL: env.apiUrl,
  headers: {
    post: {
      'Content-Type': 'application/json'
    },
    put: {
      'Content-Type': 'application/json'
    },
    common: {
      // 'Access-Control-Request-Headers': 'accept, cache-control, pragma, expires, if-modified-since, authorization',
      'Cache-Control': 'no-store, no-cache',
      Pragma: 'no-cache',
      Expires: -1,
      'If-Modified-Since': '0'
    },
    timeout: 1000 * 60 * 60
  }
})

export default instance
