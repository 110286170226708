import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  productListSaga,
  productNameListSaga,
  productListSagaByPage,
  cryptlexProductsListSaga,
  cryptlexPoliciesListSaga,
  createProductSaga,
  deleteProductSaga,
  updateProductSaga
} from './productListSaga'

const product = [
  takeLatest(generateActions(types.PRODUCTLIST).REQUEST, productNameListSaga),
  takeLatest(generateActions(types.PRODUCTSINFO).REQUEST, productListSaga),
  takeLatest(generateActions(types.PRODUCTSINFODATA).REQUEST, productListSagaByPage),
  takeLatest(generateActions(types.CRYPTLEXPRODUCTS).REQUEST, cryptlexProductsListSaga),
  takeLatest(generateActions(types.CRYPTLEXPOLICIES).REQUEST, cryptlexPoliciesListSaga),
  takeLatest(generateActions(types.ADD_NEW_PRODUCT).REQUEST, createProductSaga),
  takeLatest(generateActions(types.DELETE_PRODUCT).REQUEST, deleteProductSaga),
  takeLatest(generateActions(types.UPDATE_PRODUCT).REQUEST, updateProductSaga)
]

export default product
