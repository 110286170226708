import React from 'react'
import classes from './Toolbar.module.scss'
import MainLogo from './MainLogo/MainLogo'
import UserProfile from './UserProfile/UserProfile'
import LangConfig from './LanguageChooser/LanguageChooser'
import Menu from './Menu/Menu'
import Home from './Home/Home'
import { useTranslation } from 'react-i18next'

const Toolbar = (props: any) => {
  const { t } = useTranslation()
  return (
    <header className={classes.Toolbar}>
      <div className={classes.Block}>
        <MainLogo />
        <span className={classes.Title}>{t('License Management Portal')}</span>
      </div>
      <div className={classes.Block}>
        {props.userInfo && !!props.userInfo.role && props.userInfo.status === 'Activated' ? (
          <>
            <>
              <Home varient={'transparent'} />
              <Menu varient={'transparent'} />
            </>
            <UserProfile userInfo={props.userInfo} logout={props.logout} />
          </>
        ) : (
          <></>
        )}
        <LangConfig varient={'transparent'} clicked={() => {}} />
      </div>
    </header>
  )
}

export default Toolbar
