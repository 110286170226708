import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import {
  notificationSagaByPage,
  createNotificationSaga,
  notificationDetailsSaga,
  updateNotificationSaga,
  updateNotificationCountrySaga,
  deleteNotificationSaga
} from './notificationManagementSaga'

const notificationSaga = [
  takeLatest(generateActions(types.NOTIFICATION_LIST).REQUEST, notificationSagaByPage),
  takeLatest(generateActions(types.ADD_NOTIFICATION).REQUEST, createNotificationSaga),
  takeLatest(generateActions(types.NOTIFICATION_DETAILS).REQUEST, notificationDetailsSaga),
  takeLatest(generateActions(types.UPDATE_NOTIFICATION).REQUEST, updateNotificationSaga),
  takeLatest(
    generateActions(types.UPDATE_NOTIFICATION_COUNTRY).REQUEST,
    updateNotificationCountrySaga
  ),
  takeLatest(generateActions(types.DELETE_NOTIFICATION).REQUEST, deleteNotificationSaga)
]

export default notificationSaga
