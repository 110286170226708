import * as types from '../../../configs/constants/actionType'
import { takeLatest } from 'redux-saga/effects'
import generateActions from '../../actions/actions'
import { getDasGenerateSaga, getDasMonthSaga, getDasProductSaga } from './getDataDashBoardSaga'

const dashBoard = [
  takeLatest(generateActions(types.DAS_PRODUCT).REQUEST, getDasProductSaga),
  takeLatest(generateActions(types.DAS_MONTH).REQUEST, getDasMonthSaga),
  takeLatest(generateActions(types.DAS_GENERATE).REQUEST, getDasGenerateSaga)
]
export default dashBoard
