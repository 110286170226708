import { AxiosInstance } from 'axios'
import { store } from '../../index'

const errorNo = 401
export const handleIntercepter = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (request) => {
      const token = store.getState().loginReducer.accessToken
      //handle request herre
      request.headers.Authorization = `Bearer ${token}`
      return request
    },
    (error) => {
      console.log('called error intercepter request', error)
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      switch (error.response?.status) {
        case errorNo:
          localStorage.removeItem('user')
          localStorage.removeItem('userInfo')
          window.location.href = '/'
          break
        default:
          return Promise.reject(error)
      }
    }
  )
}
